import MuiModal from "@mui/material/Modal"
import Box from "@mui/material/Box"
import "./base-modal.scss"

export default function BaseModal({ children, ...rest }) {
    return (
        <MuiModal {...rest} >
            <Box className="root">
                {children}
            </Box>
        </MuiModal>
    )
}