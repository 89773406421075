import React from "react";
import Box from '@mui/material/Box'
import Grid from '@mui/material/Grid'
import './content.scss'

export default function Content({ children }) {
    return (
        <Grid container className="main-content" xs={12} >
            <Grid xs={10}>
                {children}
            </Grid>
        </Grid>
    )
}