import React, { useEffect, useState } from "react";
import './main-page.scss'
import MainLayout from "../../layouts/main";
import Grid from '@mui/material/Grid'
import Box from '@mui/material/Box'
import Card from '@mui/material/Card'
import CardContent from '@mui/material/CardContent';
import Typography from "@mui/material/Typography"
import Chip from "@mui/material/Chip"
import TextField from "@mui/material/TextField"
import Select from "@mui/material/Select"
import FormControl from "@mui/material/FormControl"
import InputLabel from "@mui/material/InputLabel"
import Divider from "../../components/Divider"
import MenuItem from "@mui/material/MenuItem"
import Button from "@mui/material/Button"
import IconButton from "@mui/material/IconButton"
import CloseIcon from '@mui/icons-material/Close';
import KeyboardVoiceIcon from '@mui/icons-material/KeyboardVoice';
import CancelIcon from '@mui/icons-material/Cancel';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import Tooltip from '@mui/material/Tooltip'
import { formatDate } from "../../utils/fomatDate";
import { translation } from '../../utils/symptomsList';
import apiBaseUrl from '../../utils/apiConfig';
import createApiLog from '../../utils/apiLogRequest';
import { ApiAccessTypeEnum } from '../../utils/enums/ApiAccessTypeEnum';
import { ApiAccessedProductEnum } from '../../utils/enums/ApiAccessedProductEnum';
import useWebsocket from "../../hooks/useWebsocket";
import Modal from "../../components/BaseModal";
import { CircularProgress } from '@mui/material';

export default function MainPage() {
    const [showModal, setShowModal] = useState(false)
    const [evidence, setEvidence] = useState([])
    const [symptoms, setSymptoms] = useState([])
    const [allergies, setAllergies] = useState([])
    const [medicines, setMedicines] = useState([])
    const [riscs, setRiscs] = useState([])
    const [diagnoses, setDiagnoses] = useState([])
    const [question, setQuestion] = useState('')
    const [answer1, setAnswer1] = useState(false)
    const [answer2, setAnswer2] = useState(false)
    const [answer3, setAnswer3] = useState(false)
    const [mentions, setMentions] = useState([])
    const [micDisabled, setMicDisabled] = useState(true)
    const [age, setAge] = useState('')
    const [gender, setGender] = useState('')
    const [answeredQuestions, setAnsweredQuestions] = useState([])
    const [isPrinting, setIsPrinting] = useState(false)
    const { isRecording, transcription, finalTranscription, startRecording, stopRecording } = useWebsocket()

    const [isFirstFetch, setIsFirstFetch] = useState(true);
    const [hpma, setHpma] = useState("");
    const [isda, setIsda] = useState("");
    const [alergias, setAlergias] = useState([]);
    const [conduta, setConduta] = useState("");
    const [exameFisico, setExameFisico] = useState("");
    const [medicamentosAdministrados, setMedicamentosAdministrados] = useState([]);
    const [medicamentosPrescritos, setMedicamentosPrescritos] = useState([]);
    const [queixaDuracao, setQueixaDuracao] = useState("");
    const [sintomas, setSintomas] = useState([]);
    const [loading, setLoading] = useState(false);
    const [loadingTriage, setLoadingTriage] = useState(false);

    const accessToken = localStorage.getItem('access_token');
    const translationChoicesTable = {
        "present": "Sim",
        "absent": "Não",
        "unknown": "Não Sei"
    }

    const toggleModal = () => {
        stopRecording()
        setShowModal(!showModal)
    }

    function capitalizeFirstLetter(string) {
        return string.charAt(0).toUpperCase() + string.slice(1);
    }

    const onInputChange = ({ target }) => {
        const name = target.name
        const value = target.value

        eval(`set${capitalizeFirstLetter(name)}`)(value)
    }

    const deleteEvidence = (id, type) => {
        setEvidence(evidence.filter(e => e.id !== id))

        if (type === 'symptom') {
            setSymptoms(symptoms.filter(symptom => symptom.id !== id))
        } else if (type === 'risc') {
            setRiscs(riscs.filter(risc => risc.id !== id))
        }
    }

    const deleteAllergie = (description) => {
        const newAllergies = allergies.filter(allergie => allergie !== description)

        setAllergies(newAllergies)
    }

    const deleteMedicine = (description) => {
        const newMedicines = medicines.filter(medicine => medicine !== description)

        setMedicines(newMedicines)
    }

    const printDiagnose = () => {
        setIsPrinting(true)
        window.scroll({ top: 0, behavior: 'smooth' })
        setTimeout(() => {
            window.print()
        }, 1000)
    }

    const stopAndStructure = () => {
        structureData()
        stopRecording()
    }

    const structureData = () => {
        if (finalTranscription !== '') {
            setIsFirstFetch(false);
            setLoading(true);
            const requestOptions = {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({ transcription: finalTranscription })
            };

            fetch(`${apiBaseUrl}/structured_terms`, requestOptions)
                .then(response => response.json())
                .then(data => {
                    setHpma(data.HPMA || "");
                    setIsda(data.ISDA || "");
                    setAlergias(data.alergias || []);
                    setConduta(data.conduta || "");
                    setExameFisico(data.exame_fisico || "");
                    setMedicamentosAdministrados(data.medicamentos?.administrados || []);
                    setMedicamentosPrescritos(data.medicamentos?.prescritos || []);
                    setQueixaDuracao(data.queixa_duracao || "");
                    setSintomas(data.sintomas || "");
                })
                .catch((err) => {
                    console.error(err);
                })
                .finally(() => {
                    setLoading(false);
                });
        }
    }

    useEffect(() => {
        setEvidence(current => {
            let newEvidence = [...mentions, ...current]

            return [...new Map(newEvidence.map(item => [item.id, item])).values()]
        })

        mentions.map(mention => {
            if (mention.hasOwnProperty('question')) {
                setAnsweredQuestions([...answeredQuestions, ...[mention]])
            }
        })
    }, [mentions]);

    useEffect(() => {
        for (const item of evidence) {
            switch (item.type) {
                case 'symptom':
                    if (!symptoms.map(symptom => symptom.id).includes(item.id) && translation[item.id] !== undefined)
                        setSymptoms(oldArray => [...oldArray, { description: translation[item.id], id: item.id, orth: item.orth }]);
                    break;

                case 'risk_factor':
                    if (!riscs.map(risc => risc.id).includes(item.id) && translation[item.id] !== undefined)
                        setRiscs(oldArray => [...oldArray, { description: translation[item.id], id: item.id, orth: item.orth }]);
                    break;

                default:
                    break;
            }
        }

        if (evidence.length > 2) {
            triage()
        }
    }, [evidence]);

    function triage() {
        setLoadingTriage(true);
        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");
        myHeaders.append("Authorization", "Bearer " + accessToken);

        var raw = JSON.stringify({ age: parseInt(age, 10), sex: gender, evidence });

        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: raw,
            redirect: 'follow'
        };

        fetch(`${apiBaseUrl}/diagnosis`, requestOptions)
            .then(response => response.json())
            .then(result => {
                let diag = []
                const question = result.question.text
                result.conditions.map((item) => {
                    if (item.probability >= 0.10) diag.push(`${item.name} (${item.condition_details.icd10_code}) - ${(item.probability * 100).toFixed(2)}% `)
                })

                setDiagnoses(diag)
                setQuestion(question)
                result.question.items[0].choices.map((choice) => {
                    switch (choice.label) {
                        case 'Sim':
                            setAnswer1({ question, id: result.question.items[0].id, choice_id: choice.id })
                            break;

                        case 'Não':
                            setAnswer2({ question, id: result.question.items[0].id, choice_id: choice.id })
                            break;

                        case "Não sei":
                            setAnswer3({ question, id: result.question.items[0].id, choice_id: choice.id })
                            break;
                    }
                })
                // createApiLog(ApiAccessTypeEnum.API_USAGE, ApiAccessedProductEnum.MEDICAL_TRIAGE);
            }).catch(error => console.log('error'))
            .finally(() => {
                setLoadingTriage(false);
            });
    }


    const renderCallButton = () => {
        if (micDisabled) {
            return (
                <Tooltip title="O preenchimento dos campos sexo e idade são obrigatórios para que o botão de gravação seja habilitado." placement="left" arrow>
                    <span>
                        <IconButton color="primary" disabled={micDisabled} className="call-icon-container">
                            <KeyboardVoiceIcon style={{ fontSize: "24px" }} />
                        </IconButton>
                    </span>
                </Tooltip>
            )
        }

        return (
            <IconButton color="primary" disabled={micDisabled} className="call-icon-container" onClick={startRecording}>
                <KeyboardVoiceIcon style={{ fontSize: "24px" }} />
            </IconButton>
        )
    }

    useEffect(() => {
        if (age && gender) {
            setMicDisabled(false)
            return
        }

        setMicDisabled(true)
    }, [age, gender])

    useEffect(() => {
        if (transcription != '') {
            const requestOptions = {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + accessToken,
                },
                body: JSON.stringify({ transcription, age: parseInt(age, 10), sex: gender })
            };


            fetch(`${apiBaseUrl}/translation`, requestOptions)
                .then(response => response.json())
                .then(data => {
                    if (data.mentions)
                        setMentions(data.mentions)

                    if (data.allergies.length) {
                        const newAllergies = [...new Set([...allergies, ...data.allergies])]

                        setAllergies(newAllergies)
                    }

                    if (data.medicines.length) {
                        const newMedicines = [...new Set([...medicines, ...data.medicines])]

                        setMedicines(newMedicines)
                    }
                })
                .catch((err) => {
                    console.error(err);
                    setMentions([]);
                });
        }
    }, [transcription])

    useEffect(() => {
        window.onafterprint = () => setIsPrinting(false)
    }, [])

    return (
        <MainLayout>
            <Grid container className="results-section" direction="column" gap={6}>
                <Card className="card">
                    <CardContent className="content">
                        <Grid container className="title" alignItems="center" justifyContent="space-between">
                            <Typography variant="h5" component="h3"><strong>Informações do Paciente</strong></Typography>
                            {isRecording ?
                                <IconButton color="primary" className="cancel-icon-container" onClick={stopAndStructure}>
                                    <CancelIcon style={{ fontSize: "24px" }} />
                                </IconButton>
                                :
                                renderCallButton()

                            }
                        </Grid>
                        <Box my={2} />
                        <Divider />
                        <Box py={2} />
                        <Grid container gap={2}>
                            <Grid container justifyContent="space-between" gap={6}>
                                <Grid item style={{ flexGrow: 1, flexShrink: 1, minWidth: '200px' }}>
                                    <TextField
                                        id="name"
                                        label="Nome"
                                        variant="standard"
                                        style={{ width: '100%' }}
                                    />
                                </Grid>
                                <Grid item style={{ flexGrow: 1, flexShrink: 1, minWidth: '200px' }}>
                                    <FormControl variant="standard" style={{ width: '100%' }}>
                                        <InputLabel id="demo-simple-select-standard-label">Sexo</InputLabel>
                                        <Select
                                            labelId="demo-simple-select-standard-label"
                                            id="demo-simple-select-standard"
                                            value={gender}
                                            name="gender"
                                            onChange={onInputChange}
                                        >
                                            <MenuItem value="female">Feminino</MenuItem>
                                            <MenuItem value="male">Masculino</MenuItem>
                                        </Select>
                                    </FormControl>
                                </Grid>
                                <Grid item style={{ flexGrow: 1, flexShrink: 1, minWidth: '200px' }}>
                                    <TextField
                                        id="age"
                                        label="Idade"
                                        variant="standard"
                                        type="number"
                                        value={age}
                                        name="age"
                                        onChange={onInputChange}
                                        style={{ width: '100%' }}
                                    />
                                </Grid>
                                <Grid item style={{ flexGrow: 1, flexShrink: 1, minWidth: '200px' }}>
                                    <TextField
                                        id="date"
                                        label="Data"
                                        variant="standard"
                                        disabled
                                        value={formatDate(new Date())}
                                        style={{ width: '100%' }}
                                    />
                                </Grid>
                            </Grid>
                            <Grid container className="relato-paciente-container">
                                <TextField
                                    label="Relato do Paciente"
                                    multiline
                                    variant="standard"
                                    value={finalTranscription}
                                    disabled
                                />
                            </Grid>
                        </Grid>
                    </CardContent>
                </Card>

                <Card className="card">
                    <CardContent className="content">
                        <Grid container direction="column" gap={2}>
                            <Grid container className="title" justifyContent="space-between">
                                <Typography variant="h5" component="h3"><strong>Sintomas e Diagnósticos</strong></Typography>
                            </Grid>
                            <Divider />
                            <Box my={1} />
                            <Grid container>
                                <Grid container>
                                    <Typography variant="h6"><strong>Sintomas</strong></Typography>
                                    <Tooltip title="Os sintomas são inferidos com base na transcrição da conversa durante a consulta. Cada sintoma descrito pode ser, a critério do médico, ser excluído. É recomendado revisar e, se necessário, excluir algum item antes de iniciar o processo de Triagem." placement="right">
                                        <HelpOutlineIcon fontSize='small' style={{ color: 'black', margin: "5px 0 0 10px" }} />
                                    </Tooltip>
                                </Grid>
                                <Box my={3} />
                                <Grid container gap={2}>
                                    {symptoms.length ? symptoms.map(({ id, description, orth }) => (
                                        <Tooltip title={`Evidência: ${orth}`} placement="bottom" arrow>
                                            <Chip
                                                key={id}
                                                label={description}
                                                variant="outlined"
                                                onDelete={() => deleteEvidence(id, "symptom")}
                                            />
                                        </Tooltip>
                                    )) : <Typography variant="body2">Não existem sintomas na análise ou os dados ainda estão sendo analisados.</Typography>}
                                </Grid>
                            </Grid>
                            <Box my={1} />
                            <Grid container>
                                <Grid container>
                                    <Typography variant="h6"><strong>Possíveis Alergias</strong></Typography>
                                    <Tooltip title="As Possíveis Alergias  são inferidos com base na transcrição da conversa durante a consulta. Cada alergia descrita pode ser, a critério do médico, ser excluída." placement="right">
                                        <HelpOutlineIcon fontSize='small' style={{ color: 'black', margin: "5px 0 0 10px" }} />
                                    </Tooltip>
                                </Grid>
                                <Box my={3} />
                                <Grid container gap={2}>
                                    {allergies.length ? allergies.map(allergie => (
                                        <Chip
                                            key={allergie}
                                            label={allergie}
                                            variant="outlined"
                                            onDelete={() => deleteAllergie(allergie)}
                                        />
                                    )) : <Typography variant="body2">Não existem alergias na análise ou os dados ainda estão sendo analisados.</Typography>}
                                </Grid>
                            </Grid>
                            <Box my={1} />
                            <Grid container>
                                <Grid container>
                                    <Typography variant="h6"><strong>Medicamentos</strong></Typography>
                                    <Tooltip title="Os Medicamentos  são inferidos com base na transcrição da conversa durante a consulta. Cada medicamento descrito pode ser, a critério do médico, ser excluído." placement="right">
                                        <HelpOutlineIcon fontSize='small' style={{ color: 'black', margin: "5px 0 0 10px" }} />
                                    </Tooltip>
                                </Grid>
                                <Box my={3} />
                                <Grid container gap={2}>
                                    {medicines.length ? medicines.map(medicine => (
                                        <Chip
                                            key={medicine}
                                            label={medicine}
                                            variant="outlined"
                                            onDelete={() => deleteMedicine(medicine)}
                                        />
                                    )) : <Typography variant="body2">Não existem medicamentos na análise ou os dados ainda estão sendo analisados.</Typography>}
                                </Grid>
                            </Grid>
                            <Box my={1} />
                            <Grid container>
                                <Grid container>
                                    <Typography variant="h6"><strong>Fatores de Risco</strong></Typography>
                                    <Tooltip title="Os Fatores de Risco são inferidos com base na transcrição da conversa durante a consulta. Cada fator de risco descrito pode ser, a critério do médico, ser excluído. É recomendado revisar e, se necessário, excluir algum item antes de iniciar o processo de Triagem." placement="right">
                                        <HelpOutlineIcon fontSize='small' style={{ color: 'black', margin: "5px 0 0 10px" }} />
                                    </Tooltip>
                                </Grid>
                                <Box my={3} />
                                <Grid container gap={2}>
                                    {riscs.length ? riscs.map(({ id, description, orth }) => (
                                        <Tooltip title={`Evidência: ${orth}`} placement="bottom" arrow>
                                            <Chip
                                                key={id}
                                                label={description}
                                                variant="outlined"
                                                onDelete={() => deleteEvidence(id, "risc")}
                                            />
                                        </Tooltip>
                                    )) : <Typography variant="body2">Não existem riscos na análise ou os dados ainda estão sendo analisados.</Typography>}
                                </Grid>
                            </Grid>
                            <Box my={1} />
                            <Grid container>
                                <Grid container>
                                    <Typography variant="h6"><strong>Diagnóstico Provável Gerado por IA</strong></Typography>
                                    <Tooltip title="Os diagnósticos prováveis são inferidos com base na correlação dos Sintomas e Fatores de Risco previamente preenchidos. Estas informações são geradas automaticamente e não podem ser excluídas." placement="right">
                                        <HelpOutlineIcon fontSize='small' style={{ color: 'black', margin: "5px 0 0 10px" }} />
                                    </Tooltip>
                                </Grid>
                                <Box my={3} />
                                <Grid container gap={2}>
                                    {diagnoses.length ? diagnoses.map(diagnosis => (
                                        <Chip
                                            key={diagnosis}
                                            label={diagnosis}
                                            variant="outlined"
                                        />
                                    )) : <Typography variant="body2">Não existem diagnósticos na análise ou os dados ainda estão sendo analisados.</Typography>}
                                </Grid>
                            </Grid>
                            <Box my={1} />
                            <Grid container>
                                <Typography variant="h6" component="h3" style={{ width: "100%" }}><strong>Triagem</strong></Typography>
                                <Box my={3} />
                                <Grid container direction="column" gap={2}>
                                    {answeredQuestions.length ? answeredQuestions.map(({ question, choice_id }) => (
                                        <>
                                            <Grid direction="column">
                                                <Typography variant="body1" component="p">{question}</Typography>
                                                <Typography variant="body1" component="p"><strong>Resposta:</strong> {translationChoicesTable[choice_id]}</Typography>
                                            </Grid>
                                        </>
                                    )) : <Typography variant="body2">Não existem respostas do fluxo de triagem ainda.</Typography>}
                                </Grid>
                            </Grid>
                            <Box my={2} />
                        </Grid>
                        <Box my={6} />
                        {evidence.length >= 3 && !isPrinting ?
                            <Grid container style={{ justifyContent: "flex-end" }}>
                                <Button variant="contained" onClick={toggleModal}>Iniciar Triagem</Button>
                            </Grid>
                            : null}
                    </CardContent>
                </Card>

                <Card className="card">
                    <CardContent className="content">
                        <Grid container direction="column" gap={2}>
                            <Grid item className="title" justifyContent="space-between">
                                <Typography variant="h5" component="h3"><strong>Sumário da Consulta</strong></Typography>
                            </Grid>
                            <Divider />
                            <Box my={1} />
                            {!isFirstFetch ?
                                loading ?
                                    <Box display="flex" justifyContent="center" alignItems="center" width="100%"><CircularProgress /></Box> :
                                    <Grid container>

                                        {queixaDuracao && (
                                            <Grid item xs={12}>
                                                <Typography variant="h6"><strong>Queixa e Duração</strong></Typography>
                                                <Box my={1} />
                                                <Typography variant="body2">{queixaDuracao}</Typography>
                                                <Box my={5} />
                                            </Grid>
                                        )}

                                        {hpma && (
                                            <Grid item xs={12}>
                                                <Typography variant="h6"><strong>HPMA</strong></Typography>
                                                <Box my={1} />
                                                <Typography variant="body2">{hpma}</Typography>
                                                <Box my={5} />
                                            </Grid>
                                        )}

                                        {isda && (
                                            <Grid item xs={12}>
                                                <Typography variant="h6"><strong>ISDA</strong></Typography>
                                                <Box my={1} />
                                                <Typography variant="body2">{isda}</Typography>
                                                <Box my={5} />
                                            </Grid>
                                        )}

                                        {exameFisico && (
                                            <Grid item xs={12}>
                                                <Typography variant="h6"><strong>Exame Físico</strong></Typography>
                                                <Box my={1} />
                                                <Typography variant="body2">{exameFisico}</Typography>
                                                <Box my={5} />
                                            </Grid>
                                        )}

                                        {conduta && (
                                            <Grid item xs={12}>
                                                <Typography variant="h6"><strong>Conduta</strong></Typography>
                                                <Box my={1} />
                                                <Typography variant="body2">{conduta}</Typography>
                                                <Box my={5} />
                                            </Grid>
                                        )}

                                    </Grid> : ''}
                        </Grid>
                    </CardContent>
                </Card>
                <Grid container direction="column" gap={6}>
                    <Grid item style={{ width: '100%', maxWidth: '1400px', margin: '0 auto' }}>
                        {!isPrinting &&
                            <Button variant="contained" onClick={printDiagnose} fullWidth>Imprimir Relatório</Button>
                        }
                    </Grid>
                </Grid>

            </Grid>
            <Modal open={showModal} className="close-modal" onClose={toggleModal}>
                <Grid container alignItems="center" justifyContent="space-between">
                    <Typography variant="h5"><strong>Triagem</strong></Typography>
                    <IconButton className="close-icon-button" onClick={toggleModal}>
                        <CloseIcon className="icon" />
                    </IconButton>
                </Grid>
                <Box py={2} />
                { loadingTriage ?
                    <Box display="flex" justifyContent="center" alignItems="center" width="100%" height="182px"><CircularProgress /></Box> :
                    <Grid>
                    <Typography variant="body1">{question}</Typography>
                    <Box py={2} />
                    <Grid container direction="column" gap={1}>
                        <Button variant="outlined" onClick={() => setMentions([answer1])}>Sim</Button>
                        <Button variant="outlined" onClick={() => setMentions([answer2])}>Não</Button>
                        <Button variant="outlined" onClick={() => setMentions([answer3])}>Não Sei</Button>
                    </Grid>
                </Grid>}

            </Modal>
        </MainLayout >
    )
}