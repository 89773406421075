export const translation = {
    "s_1558": "Proteção abdominal",
    "s_299": "Massa abdominal",
    "s_13": "Dor abdominal",
    "s_1598": "Dor abdominal, abaixo do umbigo",
    "s_1802": "Dor abdominal, queimação ou roedor",
    "s_1848": "Dor abdominal, cólicas",
    "s_1860": "Dor abdominal, cólicas",
    "s_1557": "Dor abdominal difusa",
    "s_1387": "Dor abdominal epigástrica",
    "s_1207": "Dor abdominal, agravada após consumo de cafeína",
    "s_15": "Dor abdominal, agravada durante a tosse ou movimento",
    "s_1202": "Dor abdominal, agravada durante a respiração profunda",
    "s_14": "Dor abdominal, agravando com o estômago vazio",
    "s_1844": "Dor abdominal, início gradual",
    "s_1852": "Dor abdominal, com duração de 2 a 7 dias",
    "s_1853": "Dor abdominal, com duração de 8 a 14 dias",
    "s_1840": "Dor abdominal, com duração inferior a 2 dias",
    "s_1842": "Dor abdominal, com duração superior a 2 semanas",
    "s_1729": "Dor abdominal, quadrante inferior esquerdo",
    "s_1854": "Dor abdominal, lado esquerdo",
    "s_1591": "Dor abdominal, quadrante superior esquerdo",
    "s_2275": "Dor abdominal localizada",
    "s_1859": "Dor abdominal, meio do ciclo",
    "s_1851": "Dor abdominal, migrando para o quadrante inferior direito",
    "s_1782": "Dor abdominal, leve",
    "s_1783": "Dor abdominal moderada",
    "s_2311": "Dor abdominal, não associada ao ciclo menstrual",
    "s_1532": "Dor abdominal periumbilical",
    "s_16": "Dor abdominal pós-prandial",
    "s_17": "Dor abdominal, pré-menstrual",
    "s_1845": "Dor abdominal, irradiando para o ombro esquerdo ou omoplata",
    "s_1846": "Dor abdominal, irradiando para a parte inferior ou média das costas",
    "s_471": "Dor abdominal, irradiando para o ombro direito ou omoplata",
    "s_1847": "Dor abdominal recorrente",
    "s_57": "Dor abdominal, reduzida pela defecação ou alívio da flatulência",
    "s_1531": "Dor abdominal, quadrante inferior direito",
    "s_1855": "Dor abdominal, lado direito",
    "s_1528": "Dor abdominal, quadrante superior direito",
    "s_1195": "Dor abdominal intensa",
    "s_1369": "Dor abdominal, aguda e penetrante",
    "s_1843": "Dor abdominal, início súbito",
    "s_1929": "Dor abdominal, insuportável",
    "s_2288": "Dor abdominal, caráter desconhecido",
    "s_2558": "Dor abdominal, início desconhecido",
    "s_2281": "Dor abdominal, localização não especificada",
    "s_1514": "Sensibilidade abdominal",
    "s_2463": "Sensibilidade abdominal, região epigástrica",
    "s_1400": "Sensibilidade abdominal, região hipogástrica",
    "s_2252": "Sensibilidade abdominal, quadrante inferior esquerdo",
    "s_1589": "Sensibilidade abdominal, quadrante superior esquerdo",
    "s_1856": "Sensibilidade abdominal, quadrante inferior direito",
    "s_1392": "Sensibilidade abdominal, quadrante superior direito",
    "s_2575": "Capacidade de controlar a hipoglicemia",
    "s_328": "Corrimento vaginal anormal",
    "s_2369": "Drenagem anormal da ferida",
    "s_598": "Abscesso",
    "s_1072": "Ausência de reflexos profundos",
    "s_2047": "Apreensão de ausência",
    "s_2294": "Crises de ausência, reflexo",
    "s_1502": "Hiperreflexia do tendão de Aquiles",
    "s_2121": "Refluxo ácido",
    "s_3": "Atividade relacionada ao sexo apesar das consequências negativas",
    "s_2635": "Perda de peso aguda",
    "s_1111": "Rosto adenóide",
    "s_833": "Agnosia",
    "s_884": "Agorafobia",
    "s_1012": "Agrafia",
    "s_817": "Desejo de álcool",
    "s_588": "Tolerância ao álcool",
    "s_818": "Síndrome de abstinência alcoólica",
    "s_1272": "Sinal Allen-Cleckley",
    "s_2648": "Alodinia",
    "s_837": "Alogia",
    "s_1458": "Amenorreia primária",
    "s_1457": "Amenorreia secundária",
    "s_1370": "Amenorreia secundária, 12 meses ou mais",
    "s_1032": "Amusia",
    "s_660": "Explosões de raiva",
    "s_608": "Queilite angular",
    "s_508": "Anedonia",
    "s_287": "Anedonia, com duração de pelo menos 2 semanas",
    "s_1123": "Anidrose",
    "s_669": "Anidrose facial unilateral",
    "s_2170": "Apresentação anictérica",
    "s_497": "Anisocoria",
    "s_1647": "Lesões cutâneas anulares",
    "s_436": "Dor anorretal",
    "s_2586": "Anúria",
    "s_120": "Ansiedade",
    "s_582": "Ataque de ansiedade",
    "s_4": "Apatia",
    "s_477": "Afonia",
    "s_6": "Apetite por alimentos salgados",
    "s_1010": "Apraxia",
    "s_1116": "Ascite",
    "s_651": "Ataxia",
    "s_2278": "Ataxia aguda",
    "s_2280": "Ataxia crônica",
    "s_2279": "Ataxia subaguda",
    "s_474": "Chiado audível",
    "s_1028": "Agnosia verbal auditiva",
    "s_857": "Estalos finos auscultativos sobre o tórax",
    "s_1316": "Roncos auscultativos sobre o tórax",
    "s_856": "Sibilos auscultativos no tórax",
    "s_2705": "Comportamento de evitação relacionado ao trauma",
    "s_2612": "Evitando ir para casa",
    "s_1322": "Sardas axilares ou inguinais",
    "s_1023": "Sinal de Babinski",
    "s_1190": "Dor nas costas",
    "s_2820": "Dor nas costas, aguda",
    "s_2822": "Dor nas costas crônica",
    "s_159": "Dor nas costas, agravada pelo esforço físico",
    "s_1206": "Dor nas costas, melhora com repouso",
    "s_53": "Dor nas costas, lombar",
    "s_38": "Dor nas costas, lombar, irradia para a parte posterior da coxa",
    "s_663": "Dor nas costas, lombar, irradiando para a virilha",
    "s_2314": "Dor nas costas, lombar, direção irradiante desconhecida",
    "s_2284": "Dor nas costas noturna",
    "s_1189": "Dor nas costas, recorrente",
    "s_1192": "Dor nas costas, intensa",
    "s_2821": "Dor nas costas, subaguda",
    "s_1198": "Dor nas costas, repentina",
    "s_1772": "Dor nas costas, torácica",
    "s_2085": "Dor nas costas, piora à palpação",
    "s_2048": "Enurese noturna",
    "s_561": "Arroto",
    "s_1073": "Ausência bilateral do reflexo do tendão de Aquiles",
    "s_698": "Enoftalmia bilateral",
    "s_1291": "Paralisia muscular bilateral",
    "s_1257": "Sinal do Bing",
    "s_137": "Compulsão alimentar",
    "s_568": "Compulsão alimentar, seguida de auto-aversão",
    "s_71": "Banqueta preta",
    "s_2523": "Fezes pretas, relacionadas à dieta ou ingestão de medicamentos",
    "s_1119": "Disfunção do esfíncter da bexiga",
    "s_115": "Sangramento do ânus",
    "s_2277": "Sangramento do ânus, intenso",
    "s_2276": "Sangramento do ânus, leve",
    "s_111": "Sangramento nas gengivas",
    "s_1386": "Sangramento nos músculos",
    "s_192": "Blefaroptose",
    "s_2508": "Blefaroptose aguda",
    "s_2511": "Blefaroptose crônica",
    "s_1807": "Bolhas",
    "s_1803": "Bolhas, bula",
    "s_1804": "Bolhas, vesículas",
    "s_309": "Inchaço",
    "s_2012": "Nível de glicose no sangue, alto",
    "s_2011": "Nível de glicose no sangue, baixo",
    "s_2013": "Nível de glicose no sangue, normal",
    "s_2014": "Nível de glicose no sangue, não medido",
    "s_2482": "Nível de glicose no sangue, muito alto",
    "s_2487": "Nível de glicose no sangue, muito baixo",
    "s_2388": "Saturação de oxigênio no sangue, entre 80% e 89%",
    "s_2387": "Saturação de oxigênio no sangue, entre 90% e 94%",
    "s_2389": "Saturação de oxigênio no sangue, inferior a 80%",
    "s_2376": "Saturação de oxigênio no sangue, normal",
    "s_2375": "Saturação de oxigênio no sangue, não medida",
    "s_2390": "Saturação de oxigênio no sangue, piora repentina",
    "s_2408": "Pressão arterial, entre 140 e 179 mmHg",
    "s_543": "Pressão arterial elevada",
    "s_1933": "Pressão arterial, mais de 180 mmHg",
    "s_2272": "Pressão arterial normal ou pré-hipertensão",
    "s_2273": "Pressão arterial, não medida",
    "s_1835": "Dores no corpo",
    "s_2180": "Deformação óssea não traumática",
    "s_2181": "Deformação óssea pós-traumática",
    "s_612": "Dor óssea",
    "s_2070": "Dor óssea, com duração inferior a uma semana",
    "s_2071": "Dor óssea, com duração superior a 1 semana",
    "s_2361": "Dor óssea noturna",
    "s_641": "Incontinência intestinal",
    "s_1553": "Ruídos intestinais diminuídos",
    "s_1552": "Ruídos intestinais, hiperativos, agudos",
    "s_534": "Bradicardia",
    "s_2806": "Infringindo a lei",
    "s_2234": "Assimetria mamária em tamanho ou formato",
    "s_1480": "Dor ou sensibilidade mamária, bilateral",
    "s_609": "Dor ou sensibilidade mamária, unilateral",
    "s_1671": "Cabelo quebrado",
    "s_2521": "Banqueta marrom",
    "s_1310": "Descoloração marrom das unhas",
    "s_1690": "Sinal de Brudzinski",
    "s_2341": "Contusão",
    "s_2827": "Contusão, escroto",
    "s_1960": "Hematomas após trauma",
    "s_2283": "Bruxismo",
    "s_1013": "Apraxia bucofacial ou orofacial",
    "s_1717": "Protuberância projetando-se através da abertura vaginal",
    "s_2731": "Joanete",
    "s_1165": "Pele queimada",
    "s_1684": "Pele queimada, rosto",
    "s_200": "Língua ardente",
    "s_1918": "Dor nas nádegas",
    "s_1330": "Máculas café com leite",
    "s_2261": "Hipertrofia da panturrilha",
    "s_1878": "Dor na panturrilha, acima do calcanhar",
    "s_232": "Dor na panturrilha, intensa ao caminhar",
    "s_732": "Dor na panturrilha, intensa ao caminhar, claudicação intermitente",
    "s_1720": "Sopros cardíacos",
    "s_1576": "Sopro carotídeo",
    "s_2409": "Catastrofizando",
    "s_589": "Cessar ou limitar o consumo de álcool",
    "s_2469": "Cessar ou limitar a ingestão de opioides",
    "s_996": "Distonia cervical",
    "s_1258": "Sinal de Chaddock",
    "s_346": "Mudança na cor de um dente",
    "s_2396": "Mudança na cor de um dente, marrom",
    "s_2393": "Mudança na cor de um dente, branco",
    "s_345": "Mudança na caligrafia",
    "s_2214": "Queimadura química",
    "s_1091": "Quemose",
    "s_50": "Dor no peito",
    "s_2096": "Dor no peito, queimação",
    "s_31": "Dor no peito, continuando durante o repouso",
    "s_51": "Dor no peito, difusa",
    "s_35": "Dor no peito, durante atividade física",
    "s_2847": "Dor no peito, durante atividade física, leve",
    "s_2843": "Dor no peito, durante atividade física, vigorosa",
    "s_2051": "Dor no peito, exacerbada pelo movimento da parede torácica",
    "s_1763": "Dor no peito, agravada pelo estresse",
    "s_2006": "Dor no peito, agravando quando deitado",
    "s_30": "Dor no peito, exacerbada com respiração profunda ou tosse",
    "s_2019": "Dor no peito, com duração entre 30 minutos e 8 horas",
    "s_2018": "Dor no peito, com duração inferior a 30 minutos",
    "s_2022": "Dor no peito, com duração superior a 8 horas",
    "s_1925": "Dor no peito, pressão",
    "s_37": "Dor no peito, irradiando entre as omoplatas",
    "s_2074": "Dor no peito, irradiando para o membro superior esquerdo",
    "s_36": "Dor no peito, irradiando para o pescoço",
    "s_1952": "Dor no peito, recorrente",
    "s_2826": "Dor no peito, resolve com repouso",
    "s_1197": "Dor no peito intensa",
    "s_1601": "Dor no peito, facada",
    "s_1509": "Dor no peito, subesternal",
    "s_1200": "Dor no peito, repentina",
    "s_1178": "Dor no peito, piora à palpação",
    "s_1580": "Dor no peito, piora à palpação das articulações costocondrais",
    "s_2134": "Retrações torácicas",
    "s_1546": "Respiração de Cheyne-Stokes",
    "s_81": "Calafrios",
    "s_2296": "Asfixia",
    "s_1456": "Coreia",
    "s_867": "Sinal de Chvostek",
    "s_1281": "Espasticidade com faca",
    "s_734": "Claudicação na parte superior da perna",
    "s_2791": "Sensibilidade na clavícula",
    "s_1094": "Limpando a garganta",
    "s_429": "Estalar ou estalar a mandíbula ao abrir ou fechar a boca",
    "s_276": "Ouvido entupido",
    "s_2256": "Contato próximo com caso COVID-19",
    "s_288": "Familiares próximos ou amigos apontando consumo excessivo de álcool",
    "s_2729": "Urina turva",
    "s_869": "Pé torto",
    "s_610": "Amígdalas revestidas",
    "s_1184": "Revestimento na língua",
    "s_2108": "Distúrbios cognitivos",
    "s_1283": "Rigidez muscular da roda dentada",
    "s_1926": "Pele fria e úmida",
    "s_408": "Pés e dedos frios",
    "s_2196": "Pés e dedos frios, unilaterais",
    "s_556": "Mãos ou dedos frios",
    "s_118": "Sensibilidade ao frio",
    "s_1328": "Deficiência de visão de cores",
    "s_2109": "Coma",
    "s_1934": "Perda total de visão, recente",
    "s_303": "Comportamento compulsivo",
    "s_831": "Confabulação",
    "s_1345": "Confusão, após trauma",
    "s_2034": "Distúrbios de consciência",
    "s_2072": "Distúrbios de consciência agudos",
    "s_2073": "Distúrbios de consciência crônicos",
    "s_2652": "Consentimento para uma entrevista sexual",
    "s_181": "Necessidade constante de sexo",
    "s_329": "Constipação",
    "s_2162": "Constipação, com duração de 3 meses ou mais",
    "s_2626": "Constipação, durando um mês ou mais",
    "s_2164": "Consumo de alimentos ou bebidas vencidas",
    "s_635": "Contencioso",
    "s_1228": "Estrabismo convergente",
    "s_1676": "Milho ou calo",
    "s_1268": "Reflexo corneano fraco ou ausente",
    "s_1273": "Sinal de Cornell",
    "s_102": "Tosse",
    "s_2268": "Tosse, produtividade alternada",
    "s_2165": "Tosse, latido",
    "s_105": "Tosse seca",
    "s_1858": "Tosse, com duração de 3 a 8 semanas",
    "s_103": "Tosse, com duração inferior a 3 semanas",
    "s_106": "Tosse com duração superior a 8 semanas",
    "s_1985": "Tosse noturna",
    "s_1924": "Tosse paroxística",
    "s_104": "Tosse, produtiva",
    "s_662": "Tosse, produtiva pela manhã",
    "s_670": "Tosse produtiva com expectoração rosada e espumosa",
    "s_526": "Tosse produtiva com expectoração amarela ou verde",
    "s_270": "Articulações rangendo durante o movimento",
    "s_2495": "Língua criada",
    "s_28": "Dor na virilha",
    "s_60": "Dor na virilha, glande do pênis",
    "s_34": "Dor na virilha, repentina",
    "s_52": "Dor na virilha, testicular ou escrotal",
    "s_1829": "Dor na virilha, vulvovaginal",
    "s_195": "Tabagismo atual",
    "s_2681": "Humor atual, deprimido",
    "s_2680": "Humor atual, elevado",
    "s_2682": "Humor atual, normal",
    "s_437": "Cianose",
    "s_1105": "Cianose sob os olhos",
    "s_553": "Cianose, dedos",
    "s_1413": "Edema macular cistóide",
    "s_1338": "Danos a 2 áreas separadas de inervação",
    "s_1555": "Sinal de dança",
    "s_1616": "Caspa",
    "s_1171": "Sinal de Darier",
    "s_611": "Urina escura",
    "s_1417": "Manchas vermelho-escuras nos vasos do disco óptico",
    "s_2795": "Enganando",
    "s_2622": "Diminuição da ingestão de líquidos",
    "s_208": "Audição diminuída",
    "s_1484": "Diminuição da audição, perda auditiva progressiva",
    "s_1538": "Diminuição da audição, perda auditiva súbita",
    "s_935": "Audição diminuída, intensidade e duração variáveis ​",
    "s_1693": "Diminuição da intensidade dos sons respiratórios",
    "s_1395": "Diminuição da intensidade dos sons respiratórios, zonas pulmonares basilares direitas",
    "s_236": "Diminuição da libido",
    "s_634": "Diminuição da necessidade de sono",
    "s_535": "Diminuição do reflexo pupilar à luz",
    "s_697": "Diminuição do turgor da pele",
    "s_1277": "Diminuição da motilidade da língua",
    "s_967": "Diminuição da acuidade visual",
    "s_97": "Obturação profunda do dente tratado",
    "s_652": "Reflexos tendinosos profundos, diminuídos",
    "s_878": "Reflexos tendinosos profundos, aumentados",
    "s_108": "Aprofundamento das bolsas periodontais",
    "s_422": "Aprofundamento da voz",
    "s_2739": "Delírio, 1 a 3 meses",
    "s_2740": "Delírio, 4 meses ou mais",
    "s_2738": "Delírio, menos de um mês",
    "s_769": "Delírios, bizarros",
    "s_677": "Delírios grandiosos",
    "s_679": "Delírios hipocondríacos",
    "s_678": "Delírios, niilistas",
    "s_680": "Delírios de controle",
    "s_675": "Delírios persecutórios",
    "s_676": "Delírios referenciais",
    "s_780": "Delírios religiosos",
    "s_768": "Delírios, autoacusação",
    "s_2744": "Delírios, roubo",
    "s_1409": "Demarcação de descolamento de retina (pigmentado ou não pigmentado)",
    "s_1106": "Dobra Dennie-Morgan",
    "s_203": "Placa dentária",
    "s_1587": "Despersonalização",
    "s_514": "Desrealização",
    "s_241": "Alterações dermatológicas",
    "s_1761": "Alterações dermatológicas, agravadas pelo estresse",
    "s_2730": "Alterações dermatológicas, nádegas",
    "s_2557": "Alterações dermatológicas, orelhas",
    "s_398": "Alterações dermatológicas, corpo inteiro",
    "s_1692": "Alterações dermatológicas, agravadas pelo consumo de álcool",
    "s_1921": "Alterações dermatológicas, agravadas pela exposição solar",
    "s_483": "Alterações dermatológicas, pálpebra",
    "s_1923": "Alterações dermatológicas, pés",
    "s_2110": "Alterações dermatológicas, área genital feminina",
    "s_350": "Alterações dermatológicas, formando uma linha",
    "s_1982": "Alterações dermatológicas, mãos",
    "s_2673": "Alterações dermatológicas, cabeça",
    "s_1510": "Alterações dermatológicas, quentes ao toque",
    "s_1680": "Alterações dermatológicas, hiperpigmentação da pele",
    "s_352": "Alterações dermatológicas, em contato direto com botões, fechos ou cosméticos",
    "s_2139": "Alterações dermatológicas intertriginosas",
    "s_2382": "Alterações dermatológicas localizadas",
    "s_1602": "Alterações dermatológicas, localizadas na região genital, cancro",
    "s_1810": "Alterações dermatológicas, localizadas na região genital",
    "s_2605": "Alterações dermatológicas localizadas nos lábios ou ao redor deles",
    "s_1808": "Alterações dermatológicas, localizadas na face",
    "s_2267": "Alterações dermatológicas, extremidades inferiores, excluindo pés",
    "s_2111": "Alterações dermatológicas, área genital masculina",
    "s_221": "Alterações dermatológicas, nos órgãos genitais semelhantes a couve-flor",
    "s_1571": "Alterações dermatológicas dolorosas",
    "s_2674": "Alterações dermatológicas, precedidas de febre ou outros sintomas de infecção do trato respiratório",
    "s_400": "Alterações dermatológicas, precedidas de dor ou coceira",
    "s_402": "Alterações dermatológicas, recorrentes durante infecções ou período menstrual",
    "s_404": "Alterações dermatológicas, superfície áspera e irregular",
    "s_245": "Alterações dermatológicas, crostas",
    "s_2059": "Alterações dermatológicas, couro cabeludo",
    "s_1988": "Alterações dermatológicas, disseminação",
    "s_2412": "Alterações dermatológicas, língua",
    "s_2060": "Alterações dermatológicas, tronco",
    "s_2266": "Alterações dermatológicas, extremidades superiores, excluindo mãos",
    "s_1191": "Alterações dermatológicas, com crises e remissões",
    "s_1957": "Pensamentos destrutivos em relação a outras pessoas ou coisas",
    "s_8": "Diarréia",
    "s_2746": "Diarréia com sangue",
    "s_412": "Diarréia espumosa",
    "s_2194": "Diarréia, com duração de 2 a 14 dias",
    "s_2328": "Diarréia, com duração de 2 a 4 semanas",
    "s_2126": "Diarréia, com duração inferior a 48 horas",
    "s_2322": "Diarréia com duração superior a 4 semanas",
    "s_1979": "Diarréia, mais de 6 evacuações por dia",
    "s_1376": "Diarréia, água de arroz",
    "s_2316": "Diarréia, personagem desconhecido",
    "s_1798": "Diferença no tamanho dos testículos",
    "s_1574": "Dificuldade em atingir o orgasmo",
    "s_269": "Dificuldade em adquirir novas informações",
    "s_266": "Dificuldade em morder e mastigar",
    "s_727": "Dificuldade de vínculo com o bebê",
    "s_1154": "Dificuldade em fechar completamente os olhos",
    "s_2760": "Dificuldade em organizar objetos",
    "s_2771": "Dificuldade em organizar objetos, 3 dias ou menos",
    "s_2772": "Dificuldade em organizar objetos, 4 a 6 dias",
    "s_2774": "Dificuldade em organizar objetos, 6 meses ou mais",
    "s_2773": "Dificuldade em organizar objetos, entre 7 dias e 6 meses",
    "s_2775": "Dificuldade em organizar tarefas ou atividades",
    "s_2778": "Dificuldade em organizar tarefas ou atividades, 3 dias ou menos",
    "s_2779": "Dificuldade em organizar tarefas ou atividades, 4 a 6 dias",
    "s_2781": "Dificuldade em organizar tarefas ou atividades, 6 meses ou mais",
    "s_2780": "Dificuldade em organizar tarefas ou atividades, entre 7 dias e 6 meses",
    "s_2784": "Dificuldade em esperar turnos",
    "s_284": "Apetite diminuído",
    "s_1035": "Respostas de medo diminuídas",
    "s_1021": "Reflexos superficiais diminuídos",
    "s_207": "Diplopia",
    "s_1232": "Diplopia ao olhar para baixo e medialmente",
    "s_1235": "Diplopia ao olhar em qualquer direção",
    "s_1229": "Diplopia com dispersão horizontal de imagem",
    "s_2201": "Diplopia, com duração de 24 horas ou mais",
    "s_2200": "Diplopia, com duração inferior a 24 horas",
    "s_2254": "Atendimento direto ao caso COVID-19",
    "s_297": "Descarga do ouvido",
    "s_1577": "Descarga do ouvido, com sangue",
    "s_1994": "Descarga do ouvido, purulenta",
    "s_302": "Descarga de bolsas periodontais",
    "s_369": "Descarga da uretra",
    "s_1109": "Descarga na cavidade timpânica",
    "s_301": "Descarga sob o prepúcio",
    "s_95": "Desconforto ao falar, maxilo-facial",
    "s_1007": "Desinibição",
    "s_1265": "Perda sensorial dissociada",
    "s_711": "Amnésia dissociativa de trauma",
    "s_501": "Erro de julgamento de distância",
    "s_1236": "Estrabismo divergente",
    "s_1731": "Manobra de Dix-Hallpike, nistagmo rotatório",
    "s_370": "Tontura",
    "s_1479": "Tontura, rotação da cabeça",
    "s_2023": "Tontura recorrente",
    "s_2118": "Tontura, insuportável",
    "s_936": "Tontura, vertigem",
    "s_828": "Beber álcool sozinho",
    "s_827": "Beber álcool no trabalho",
    "s_819": "Beber álcool apesar da consciência dos efeitos nocivos",
    "s_109": "Beber álcool para aliviar a ressaca",
    "s_198": "Beber grandes quantidades de álcool",
    "s_199": "Beber grandes quantidades de álcool de uma só vez",
    "s_2473": "Impulsione a musculatura",
    "s_432": "Canto da boca caído",
    "s_974": "Ataque de queda",
    "s_823": "Dirigir embriagado",
    "s_489": "Corrimento seco nas pálpebras",
    "s_2094": "Olhos secos",
    "s_247": "Boca seca",
    "s_542": "Pele seca",
    "s_385": "Pele seca e rachada, sangrando ocasionalmente",
    "s_59": "Dismenorreia",
    "s_1432": "Dispepsia",
    "s_962": "Disfagia",
    "s_1779": "Disfagia esofágica",
    "s_1776": "Disfagia orofaríngea",
    "s_2571": "Disfagia grave",
    "s_68": "Disfonia",
    "s_1958": "Disfonia, mais de 2 semanas",
    "s_88": "Dispneia",
    "s_2075": "Dispneia aguda, após trauma",
    "s_2203": "Dispneia, após alguns minutos de caminhada",
    "s_2205": "Dispneia em repouso",
    "s_2176": "Dispneia, com duração de 1 a 24 horas",
    "s_90": "Dispneia, com duração entre 1 dia e 4 semanas",
    "s_2835": "Dispneia, com duração entre 1 dia e 4 semanas, piora súbita",
    "s_1625": "Dispneia, com duração superior a 4 semanas",
    "s_2834": "Dispneia, com duração superior a 4 semanas, piora súbita",
    "s_2204": "Dispneia aos esforços",
    "s_563": "Dispneia, ortopneia",
    "s_1317": "Dispnéia paroxística noturna",
    "s_92": "Dispneia, iniciada há menos de 1 hora",
    "s_2231": "Dispneia, gravidade desconhecida",
    "s_1185": "Dispneia, com crises e remissões",
    "s_1298": "Distonia",
    "s_291": "Inchaço do canal auditivo",
    "s_47": "Dor de ouvido",
    "s_476": "Dor de ouvido, sensibilidade ao redor da orelha",
    "s_234": "Contusões fáceis",
    "s_1696": "Ecolalia",
    "s_700": "Edema",
    "s_623": "Edema, ambos os tornozelos",
    "s_1995": "Edema, ambos os pés",
    "s_2093": "Edema, ambas as mãos",
    "s_175": "Edema, ambos os membros inferiores",
    "s_2790": "Edema, clavícula",
    "s_1581": "Edema, junções costocondrais",
    "s_701": "Edema diurno",
    "s_699": "Edema, dependente da posição do corpo",
    "s_1635": "Edema, articulação do cotovelo",
    "s_2718": "Edema, rosto inteiro",
    "s_616": "Edema, rosto",
    "s_1311": "Edema, dedo",
    "s_1652": "Edema, articulação do quadril",
    "s_170": "Edema, articulação do joelho",
    "s_1433": "Edema labial",
    "s_363": "Edema, pescoço",
    "s_1996": "Edema, nariz",
    "s_171": "Edema, um tornozelo",
    "s_2103": "Edema, um pé",
    "s_1464": "Edema, uma mão",
    "s_173": "Edema, um membro inferior",
    "s_2207": "Edema, pênis",
    "s_1442": "Edema periorbital, ambos os olhos",
    "s_2639": "Edema periorbital, olhos",
    "s_2627": "Edema periorbital, um olho",
    "s_562": "Edema, corrosão",
    "s_176": "Edema, escroto",
    "s_1655": "Edema, articulação do ombro",
    "s_1997": "Edema, dedo do pé",
    "s_1447": "Edema, membro superior",
    "s_1431": "Edema, articulação do punho",
    "s_2825": "Choque elétrico",
    "s_1989": "Queimadura elétrica",
    "s_2383": "Hipomineralização do esmalte",
    "s_1166": "Hipoplasia do esmalte",
    "s_2381": "Lesões de esmalte",
    "s_272": "Lesões de esmalte, cárie",
    "s_2391": "Lesões de esmalte, manchas",
    "s_2384": "Lesões de esmalte, simétricas",
    "s_2292": "Linfonodos axilares aumentados",
    "s_219": "Seios aumentados",
    "s_2248": "Linfonodos cervicais aumentados",
    "s_2247": "Frente do pescoço ampliada",
    "s_1052": "Rins aumentados",
    "s_220": "Linfonodos aumentados",
    "s_1057": "Glândulas salivares aumentadas",
    "s_2465": "Lado ampliado do pescoço",
    "s_2580": "Veia dilatada no abdômen",
    "s_2600": "Veia torácica dilatada",
    "s_2579": "Veia dilatada do membro inferior",
    "s_2467": "Pescoço inteiro ampliado",
    "s_480": "Aumento das mãos, pés ou rosto",
    "s_1390": "Aumento da tonsila palatina unilateral",
    "s_1102": "Aumento das tonsilas palatinas, bilateral",
    "s_1103": "Aumento do tecido linfóide na faringe",
    "s_2665": "Episódio de humor deprimido, início periparto",
    "s_2677": "Episódio de humor deprimido induzido por substância",
    "s_633": "Episódio de humor elevado",
    "s_2669": "Episódio de humor elevado, com duração de 3 dias ou menos",
    "s_2670": "Episódio de humor elevado, com duração de 4 a 6 dias",
    "s_2671": "Episódio de humor elevado, com duração de 7 dias ou mais",
    "s_2672": "Episódio de humor elevado, induzido por substância",
    "s_169": "Episódios de humor deprimido",
    "s_2667": "Episódios de humor deprimido, com duração de 1 a 2 anos",
    "s_2666": "Episódios de humor deprimido, com duração entre 2 semanas e 1 ano",
    "s_2663": "Episódios de humor deprimido, com duração inferior a 2 semanas",
    "s_2664": "Episódios de humor deprimido, com duração superior a 2 anos",
    "s_835": "Episódios de humor deprimido, padrão sazonal",
    "s_733": "Disfunção erétil",
    "s_229": "Eritema",
    "s_202": "Eritema migratório",
    "s_759": "Eritema em forma de V no peito",
    "s_1955": "Eritema ao redor de ambos os olhos",
    "s_2633": "Eritema ao redor de um dos olhos",
    "s_2630": "Eritema ao redor de um olho",
    "s_2484": "Eritema no peito",
    "s_1468": "Eritema facial",
    "s_230": "Eritema facial, em forma de borboleta",
    "s_2559": "Eritema nos pés",
    "s_1314": "Eritema, dedo",
    "s_433": "Eritema, prepúcio ou cabeça do pênis",
    "s_557": "Eritema, mãos",
    "s_323": "Eritema articular",
    "s_1469": "Eritema, grande parte do corpo",
    "s_325": "Eritema, membro",
    "s_2477": "Eritema, membros, simétrico",
    "s_2552": "Eritema, extremidades inferiores, excluindo pés",
    "s_2481": "Eritema no pescoço",
    "s_1125": "Eritema palmar",
    "s_1791": "Eritema no couro cabeludo",
    "s_2236": "Eritema, escroto",
    "s_758": "Eritema, ombros e nuca",
    "s_2003": "Eritema, dedo do pé",
    "s_2371": "Eritema não especificado",
    "s_2551": "Eritema, extremidades superiores, excluindo mãos",
    "s_1999": "Eritema, vulva",
    "s_1498": "Amígdalas eritematosas",
    "s_2737": "Resposta de sobressalto exagerada",
    "s_132": "Excesso de crescimento de cabelo",
    "s_2289": "Medo excessivo de separação",
    "s_2496": "Perda excessiva de tecido dentário duro",
    "s_2758": "Atividade motora excessiva",
    "s_2759": "Atividade motora excessiva, 3 dias ou menos",
    "s_2761": "Atividade motora excessiva, 4 a 6 dias",
    "s_2763": "Atividade motora excessiva, 6 meses ou mais",
    "s_2762": "Atividade motora excessiva, 7 dias a 6 meses",
    "s_1304": "Produção excessiva de saliva",
    "s_216": "Suor excessivo",
    "s_122": "Produção excessiva de lágrimas",
    "s_2789": "Verbosidade excessiva",
    "s_725": "Preocupação excessiva com a saúde de uma criança",
    "s_2752": "Esgotamento relacionado ao estudo ou trabalho",
    "s_1163": "Exoftalmia",
    "s_2141": "Descarga ocular",
    "s_2188": "Secreção ocular, purulenta",
    "s_2329": "Secreção ocular, espessa e clara",
    "s_606": "Flashes de olhos",
    "s_2572": "Lesão ocular",
    "s_493": "Dor nos olhos",
    "s_2242": "Dor nos olhos, insuportável",
    "s_1983": "Lesão palpebral, coceira",
    "s_485": "Lesão palpebral, dolorosa",
    "s_486": "Lesão palpebral, vermelha e quente",
    "s_484": "Lesão palpebral, caroço vermelho com ponta amarela",
    "s_235": "Contração das pálpebras",
    "s_488": "Olhos sensíveis à luz",
    "s_1156": "Paresia muscular facial",
    "s_2349": "Paresia muscular facial, com duração entre 24 horas e 4 semanas",
    "s_2348": "Paresia muscular facial, com duração inferior a 24 horas",
    "s_2350": "Paresia muscular facial, com duração superior a 4 semanas",
    "s_149": "Paresia muscular facial unilateral",
    "s_1425": "Espasmos musculares faciais",
    "s_478": "Dor facial",
    "s_2499": "Dor facial bilateral",
    "s_2544": "Dor facial, constante",
    "s_1203": "Dor facial, mais de 2 horas",
    "s_1436": "Dor facial, seios paranasais",
    "s_427": "Dor facial, periódica",
    "s_1194": "Dor facial intensa",
    "s_425": "Dor facial, facada",
    "s_426": "Dor facial, desencadeada ao comer, tocar ou escovar os dentes",
    "s_424": "Dor facial unilateral",
    "s_2543": "Dor facial, não provocada",
    "s_2100": "Fadiga",
    "s_1547": "Fadiga, mais de 6 meses",
    "s_1332": "Fadiga, fraqueza após exercício",
    "s_2645": "Fadiga, piora da tolerância ao exercício",
    "s_2615": "Medo de uma determinada pessoa",
    "s_2470": "Medo de envelhecer",
    "s_1087": "Medo de enlouquecer",
    "s_1088": "Medo de perder o controle",
    "s_157": "Medo da obesidade",
    "s_2832": "Medo de interações sociais",
    "s_244": "Medo de objeto, situação ou ação específica",
    "s_2831": "Medo ou evitação de comer na presença de outra pessoa",
    "s_274": "Sentindo calor",
    "s_205": "Sentimento de culpa",
    "s_206": "Sentimento de culpa ou vergonha após consumir álcool",
    "s_629": "Sentimento de desamparo",
    "s_632": "Sentimento de desesperança",
    "s_435": "Sensação de evacuação incompleta da bexiga",
    "s_1565": "Sensação de plenitude retal",
    "s_201": "Sensação de areia sob as pálpebras",
    "s_1716": "Sensação de plenitude vaginal",
    "s_1175": "Sensação de peso no escroto",
    "s_419": "Sentir-se acima do peso apesar do peso normal a baixo",
    "s_279": "Sentir pressão ou pulsação dentro do dente",
    "s_2186": "Queimadura genital feminina",
    "s_7": "Infertilidade feminina",
    "s_2010": "Ruptura das membranas fetais",
    "s_2125": "Ruptura das membranas fetais, líquido amniótico purulento ou fétido",
    "s_98": "Febre",
    "s_100": "Febre, entre 100,4 e 104°F ou 38 e 40°C",
    "s_99": "Febre, entre 98,6 e 100,4°F ou 37 e 38°C",
    "s_1186": "Febre, crises e remissões",
    "s_2000": "Febre superior a 104°F ou 40°C",
    "s_2320": "Febre, com duração de 3 a 7 dias",
    "s_2319": "Febre, com duração inferior a 3 dias",
    "s_2321": "Febre, com duração superior a 7 dias",
    "s_1172": "Febre periódica",
    "s_1820": "Febre, temperatura não medida",
    "s_636": "Imprudência financeira",
    "s_2828": "Testículo firme",
    "s_2182": "Dor no flanco",
    "s_2253": "Pé chato",
    "s_504": "Urina espumosa",
    "s_1909": "Queda do pé",
    "s_578": "Dor nos pés",
    "s_1645": "Dor no pé, dor na parte de trás do pé durante a elevação do calcanhar unipodal",
    "s_2560": "Inserção de corpo estranho",
    "s_204": "Postura inclinada para frente",
    "s_525": "Fezes com mau cheiro",
    "s_2064": "Urina com mau cheiro",
    "s_505": "Infecções frequentes",
    "s_532": "Infecções frequentes, trato respiratório inferior",
    "s_531": "Infecções frequentes, trato respiratório superior",
    "s_2650": "Heteroagressividade física frequente",
    "s_215": "Micção frequente",
    "s_2366": "Bossa frontal",
    "s_990": "Sinais de liberação frontal",
    "s_2336": "Galactorréia em homens",
    "s_547": "Galactorreia em mulheres",
    "s_2032": "Jogos de azar",
    "s_2029": "Jogos de azar, mentir sobre a extensão",
    "s_2024": "Jogo patológico",
    "s_2271": "Sintomas gástricos, após ingestão de glúten",
    "s_1570": "Sintomas gástricos, após ingestão de lactose",
    "s_1767": "Sintomas gástricos relacionados ao estresse",
    "s_583": "Ansiedade geral",
    "s_2246": "Lesão genital em mulher",
    "s_2245": "Lesão genital em homem",
    "s_253": "Coceira genital",
    "s_1226": "Genu varum",
    "s_2260": "Recessão gengival",
    "s_1090": "Olhos vidrados",
    "s_513": "Sensação de globo",
    "s_1302": "Sinal de Goldflam",
    "s_1263": "Sinal Gonda-Allen",
    "s_1254": "Sinal de Gordon",
    "s_756": "Pápulas de Gottron",
    "s_2262": "Sinal de Gowers",
    "s_2582": "Dor na virilha",
    "s_1250": "Dor na virilha, exacerbada por tosse, espirro, riso ou exercício",
    "s_322": "Eritema gengival",
    "s_19": "Dor nas gengivas",
    "s_586": "Ginecomastia",
    "s_121": "Perda de cabelo",
    "s_2520": "Perda de cabelo androgenética",
    "s_1665": "Perda de cabelo generalizada",
    "s_618": "Leucoplasia peluda",
    "s_65": "Halitose",
    "s_2742": "Alucinações, 1 a 3 meses",
    "s_2743": "Alucinações, 4 meses ou mais",
    "s_682": "Alucinações auditivas",
    "s_2741": "Alucinações, menos de um mês",
    "s_681": "Alucinações visuais",
    "s_2041": "Halos ao redor das luzes",
    "s_1246": "Nódulo duro na região inguinal",
    "s_1248": "Nódulo duro na região inguinal, coloração escura ou azulada",
    "s_1247": "Nódulo duro na região inguinal, irredutível",
    "s_1249": "Nódulo duro na região inguinal, sensível",
    "s_1346": "Exsudados retinais duros",
    "s_191": "Queda de cabeça",
    "s_1230": "Inclinação da cabeça para evitar diplopia",
    "s_21": "Dor de cabeça",
    "s_1535": "Dor de cabeça crônica",
    "s_1901": "Dor de cabeça crônica, com duração de 3 a 7 dias",
    "s_1870": "Dor de cabeça crônica, com duração de 4 a 72 horas",
    "s_1868": "Dor de cabeça crônica, com duração de 5 minutos a 4 horas",
    "s_1907": "Dor de cabeça crônica, com duração inferior a 5 minutos",
    "s_1762": "Dor de cabeça, agravada pelo aumento do estresse",
    "s_1871": "Dor de cabeça, agravada pela atividade física",
    "s_625": "Dor de cabeça, agravada pela inclinação da cabeça para frente",
    "s_1864": "Dor de cabeça, insuportável",
    "s_1349": "Dor de cabeça, testa",
    "s_24": "Dor de cabeça generalizada",
    "s_604": "Dor de cabeça, lancinante",
    "s_2566": "Dor de cabeça localizada",
    "s_1780": "Dor de cabeça leve",
    "s_1781": "Dor de cabeça moderada",
    "s_970": "Dor de cabeça occipital",
    "s_2568": "Dor de cabeça parietal",
    "s_23": "Dor de cabeça, pressão",
    "s_25": "Dor de cabeça, pulsante",
    "s_1912": "Dor de cabeça recente",
    "s_2190": "Dor de cabeça recente, com duração de 1 a 24 horas",
    "s_2189": "Dor de cabeça recente, com duração inferior a 1 hora",
    "s_2191": "Dor de cabeça recente, com duração superior a 1 dia",
    "s_605": "Dor de cabeça, séries durante um período de tempo",
    "s_1193": "Dor de cabeça intensa",
    "s_1905": "Dor de cabeça, início súbito",
    "s_1911": "Dor de cabeça na região temporal",
    "s_22": "Dor de cabeça unilateral",
    "s_2567": "Dor de cabeça, localização não especificada",
    "s_2516": "Dor de cabeça, acordar do sono",
    "s_799": "Dor de cabeça, pior pela manhã",
    "s_338": "Azia",
    "s_273": "Pernas pesadas no final do dia",
    "s_158": "Períodos menstruais intensos ou prolongados",
    "s_1922": "Teste de queda do calcanhar",
    "s_1170": "Hemartrose",
    "s_306": "Hematêmese",
    "s_814": "Hemianopsia bitemporal",
    "s_116": "Hemoptise",
    "s_2651": "Hemoptise maciça",
    "s_1051": "Hepatomegalia",
    "s_2546": "Íris heterocrômicas",
    "s_1295": "Soluços",
    "s_1108": "Palato arqueado alto",
    "s_2714": "História de uma situação estressante",
    "s_2710": "História de morte inesperada de um ente querido",
    "s_147": "História de anemia",
    "s_2706": "História de risco de morte",
    "s_2707": "História de experimentar ou testemunhar um desastre natural",
    "s_2708": "História de vivenciar ou testemunhar um grave acidente de trânsito",
    "s_2574": "História de episódios hipoglicêmicos",
    "s_212": "História de aborto espontâneo",
    "s_2709": "História de situação traumática",
    "s_757": "Sinal de coldre",
    "s_957": "Hemianopsia homônima",
    "s_373": "Crosta cor de mel na pele",
    "s_280": "Ondas de calor",
    "s_1481": "Hiperalgesia",
    "s_760": "Hiperqueratose nas mãos",
    "s_1891": "Hipersensibilidade a cheiros",
    "s_1150": "Hipersensibilidade ao som",
    "s_2727": "Hipervigilância",
    "s_2507": "Alucinações hipnagógicas",
    "s_1876": "Hipomenorreia",
    "s_180": "Hipopigmentação da pele",
    "s_570": "Hipópio",
    "s_533": "Hipotensão",
    "s_341": "Ilusão de que o ambiente é maior ou menor do que realmente é",
    "s_317": "Equilíbrio prejudicado ao caminhar",
    "s_631": "Concentração prejudicada",
    "s_1241": "Movimento ocular para baixo prejudicado",
    "s_1227": "Movimento lateral dos olhos prejudicado",
    "s_1240": "Movimento ocular medial prejudicado",
    "s_316": "Memória prejudicada",
    "s_825": "Memória prejudicada, amnésia relacionada ao álcool",
    "s_2490": "Memória prejudicada, desenvolvida há mais de 24 horas",
    "s_2489": "Memória prejudicada, desenvolvida em 24 horas",
    "s_146": "Memória prejudicada, esquecimento de nomes de amigos e familiares",
    "s_830": "Memória prejudicada, curto prazo",
    "s_1095": "Cheiro prejudicado",
    "s_716": "Funcionamento social prejudicado",
    "s_1239": "Movimento ocular ascendente prejudicado",
    "s_320": "Visão prejudicada",
    "s_2464": "Visão prejudicada, bilateral",
    "s_1819": "Visão prejudicada, um olho",
    "s_1818": "Visão prejudicada, início recente",
    "s_1146": "Visão prejudicada, dor nas têmporas ao lado do problema de visão",
    "s_1384": "Comportamento impulsivo",
    "s_2086": "Incapacidade de suportar peso, após trauma",
    "s_2749": "Incapacidade de controlar a hipoglicemia",
    "s_1551": "Incapacidade de eliminar flatos",
    "s_150": "Incapacidade de parar de beber álcool",
    "s_223": "Incapacidade de interromper o fluxo de urina",
    "s_277": "Aumento da circunferência abdominal",
    "s_311": "Aumento do apetite",
    "s_1488": "Fase expiratória aumentada",
    "s_421": "Aumento da libido",
    "s_991": "Aumento do reflexo do masseter",
    "s_361": "Aumento da necessidade de experiências ou sensações sexuais para atingir o efeito desejado",
    "s_1278": "Aumento do reflexo palatino",
    "s_1279": "Aumento do reflexo faríngeo",
    "s_310": "Aumento da sede",
    "s_2454": "Inserção de corpo estranho, objeto perigoso",
    "s_917": "Insônia",
    "s_1383": "Relacionamentos intensos e sem compromisso",
    "s_2510": "Perda da papila interdental",
    "s_358": "Fluxo de urina intermitente",
    "s_1084": "Atrofia dos músculos interósseos, pés",
    "s_1083": "Atrofia dos músculos interósseos, mãos",
    "s_1695": "Dificuldades de relacionamento interpessoal",
    "s_1416": "Anormalidades microvasculares intrarretinianas",
    "s_2796": "Comportamentos repetitivos involuntários",
    "s_2794": "Comportamentos repetitivos involuntários, motores",
    "s_2797": "Comportamentos repetitivos involuntários, vocais",
    "s_1411": "Neovascularização da íris",
    "s_1440": "Menstruações irregulares",
    "s_540": "Irritabilidade",
    "s_80": "Irritabilidade, quando incapaz de se envolver em atividades sexuais",
    "s_614": "Coceira após um banho quente",
    "s_249": "Comichão ao redor do ânus",
    "s_1563": "Comichão nos olhos",
    "s_255": "Comichão dentro do ouvido",
    "s_252": "Comichão no nariz ou na garganta",
    "s_1271": "Sinal de Jacobsohn",
    "s_1718": "Lesão de Janeway",
    "s_332": "Icterícia",
    "s_2569": "Icterícia cutânea",
    "s_336": "Icterícia, durante doença infecciosa",
    "s_335": "Icterícia, após esforço físico intenso",
    "s_2563": "Icterícia recorrente",
    "s_334": "Icterícia relacionada ao estresse",
    "s_1961": "Dor na mandíbula",
    "s_2501": "Dor na mandíbula, associada ao toque",
    "s_2498": "Dor na mandíbula, pior pela manhã",
    "s_359": "Deformação articular, não traumática",
    "s_2087": "Deformação articular pós-traumática",
    "s_44": "Dor nas articulações",
    "s_576": "Dor nas articulações, agravada pelo tempo frio ou úmido",
    "s_1621": "Dor nas articulações, tornozelo",
    "s_2591": "Dor nas articulações, ambos os joelhos",
    "s_2592": "Dor nas articulações, ambos os joelhos, durante o movimento",
    "s_1623": "Dor nas articulações, durante o movimento do tornozelo",
    "s_1634": "Dor nas articulações, durante o movimento do cotovelo",
    "s_1823": "Dor nas articulações, durante o movimento do quadril",
    "s_1805": "Dor nas articulações, durante o movimento do ombro",
    "s_1641": "Dor nas articulações, durante o movimento do polegar",
    "s_1636": "Dor nas articulações, durante o movimento do punho",
    "s_2610": "Dor nas articulações, em qualquer joelho",
    "s_1632": "Dor nas articulações, cotovelo",
    "s_79": "Dor nas articulações, hálux",
    "s_11": "Dor nas articulações, quadril",
    "s_2299": "Dor nas articulações, inflamatória",
    "s_1610": "Dor nas articulações, joelho, durante o movimento",
    "s_2298": "Dor nas articulações, mecânica",
    "s_581": "Dor nas articulações, um joelho",
    "s_41": "Dor nas articulações, intensa, após trauma",
    "s_1654": "Dor nas articulações, ombro",
    "s_1201": "Dor nas articulações, repentina",
    "s_1656": "Dor nas articulações, sensibilidade",
    "s_1639": "Dor nas articulações, polegar",
    "s_1430": "Dor nas articulações, punho",
    "s_575": "Rigidez articular",
    "s_258": "Rigidez articular, rigidez nas mãos pela manhã",
    "s_259": "Rigidez articular, diminui após o exercício",
    "s_1472": "Anel Kayser-Fleischer",
    "s_1689": "Sinal de Kernig",
    "s_1745": "Instabilidade do joelho",
    "s_664": "Manchas de Koplik",
    "s_1451": "Respiração de Kussmaul",
    "s_2783": "Falta de atenção aos detalhes",
    "s_778": "Falta de limites nas relações interpessoais",
    "s_1698": "Falta de contato visual",
    "s_1006": "Falta de iniciativa, motivação ou motivação",
    "s_964": "Lateropulsão",
    "s_1282": "Rigidez do tubo de chumbo",
    "s_550": "Opacidade da lente",
    "s_666": "Manchas semelhantes a leopardo na pele",
    "s_2354": "Lesões localizadas na faringe posterior",
    "s_1378": "Sinal de Levine",
    "s_1054": "Sinal de Lhermitte",
    "s_1793": "Ovos de piolhos ou lêndeas nas hastes do cabelo",
    "s_1324": "Nódulos de Lisch",
    "s_763": "Livedo reticular",
    "s_1407": "Vasos sanguíneos escuros locais da retina que ficam em dobras",
    "s_1405": "Elevação local da retina",
    "s_1406": "Descoloração cinza local da retina",
    "s_1408": "Ondulação retinal local",
    "s_2453": "Obturação dentária solta",
    "s_228": "Dente solto não relacionado a troca de dente",
    "s_1115": "Perda de pelos corporais",
    "s_1543": "Perda de consciência",
    "s_2472": "Perda de consciência durante o exercício",
    "s_2468": "Perda de consciência, durante permanência prolongada",
    "s_2471": "Perda de consciência, na posição sentada ou deitada",
    "s_2474": "Perda de consciência, em local quente",
    "s_2303": "Perda de consciência, mais de 1 minuto",
    "s_2302": "Perda de consciência, transitória",
    "s_2475": "Perda de consciência, sob estresse",
    "s_190": "Perda de consciência, com micção ou defecação",
    "s_2634": "Perda de movimento articular",
    "s_622": "Perda de massa muscular",
    "s_564": "Perda de massa muscular, mãos",
    "s_959": "Perda de sensibilidade no rosto",
    "s_1159": "Perda de sensibilidade na face, unilateral",
    "s_1070": "Perda da sensação de vibração nas partes distais das extremidades",
    "s_1394": "Perda das papilas linguais",
    "s_1027": "Perda de visão no quadrante superior",
    "s_746": "Perda ou comprometimento da propriocepção",
    "s_747": "Perda ou comprometimento da sensação de temperatura",
    "s_507": "Perdeu a vontade de viver",
    "s_1404": "Pressão intraocular baixa, diferença de 4 a 5 mmHg no olho afetado em comparação com o outro olho",
    "s_155": "Baixo senso de autoestima",
    "s_1725": "Palidez dos membros inferiores",
    "s_1618": "Sensibilidade nos membros inferiores",
    "s_1622": "Sensibilidade nos membros inferiores, panturrilha",
    "s_2309": "Nódulo na região poplítea",
    "s_1173": "Caroço no escroto",
    "s_2581": "Caroço no escroto, veia dilatada",
    "s_420": "Mentir sobre comer ou esconder comida",
    "s_1733": "Dor nos gânglios linfáticos",
    "s_2187": "Queimadura genital masculina",
    "s_2052": "Infertilidade masculina",
    "s_289": "Maloclusões",
    "s_2403": "Desvio mandibular ao abrir a boca",
    "s_2733": "Comportamento maníaco",
    "s_2735": "Comportamento maníaco, empréstimos frequentes",
    "s_2734": "Comportamento maníaco, gastos imprudentes",
    "s_1403": "Aluno de Marcus Gunn",
    "s_2352": "Dificuldades matemáticas",
    "s_665": "Erupção cutânea de sarampo",
    "s_1261": "Sinal de Mendel-Bekhterev",
    "s_415": "Período menstrual com mais de 3 semanas de atraso",
    "s_1768": "Distúrbios mentais exacerbados pelo estresse",
    "s_360": "Microstomia",
    "s_537": "Miose",
    "s_1274": "Sinal de Moniz",
    "s_646": "Mudanças de humor",
    "s_2686": "Mudanças de humor, 1 a 2 anos",
    "s_730": "Mudanças de humor, diurnas",
    "s_2687": "Mudanças de humor, padrão extremamente rápido",
    "s_2676": "Mudanças de humor, menos de 1 ano",
    "s_2786": "Mudanças de humor, padrão de longa data",
    "s_2675": "Mudanças de humor, mais de 2 anos",
    "s_1297": "Retardo motor",
    "s_1104": "Respiração bucal",
    "s_2065": "Dor na boca",
    "s_2421": "Dor na boca, depois de comer alimentos picantes ou ácidos",
    "s_1": "Úlcera bucal",
    "s_1210": "Palidez mucosa",
    "s_1213": "Palidez mucosa repentina",
    "s_642": "Secreção mucosa nas fezes",
    "s_2357": "Várias áreas vermelhas, maiores que 1 cm",
    "s_2370": "Várias alterações em vermelho, tamanhos diferentes",
    "s_2359": "Múltiplas manchas vermelhas, menores que 1 cm",
    "s_567": "Sinal de Murphy",
    "s_503": "Cãibras musculares",
    "s_2506": "Cãibras musculares, hipnagógicas",
    "s_2638": "Cãibras musculares, membros inferiores",
    "s_2494": "Cãibras musculares noturnas",
    "s_650": "Fraqueza muscular",
    "s_2330": "Fraqueza muscular geral",
    "s_1142": "Mialgia",
    "s_1766": "Mialgia, agravada pelo estresse",
    "s_2455": "Mialgia, após esforço físico intenso",
    "s_536": "Midríase",
    "s_1476": "Miringite",
    "s_603": "Baqueteamento de unhas",
    "s_1167": "Distrofia ungueal",
    "s_2005": "Dor nas unhas",
    "s_107": "Catarro nasal",
    "s_2845": "Catarro nasal agudo",
    "s_2846": "Catarro nasal crônico",
    "s_2153": "Catarro nasal, mucóide",
    "s_2154": "Catarro nasal, purulento",
    "s_331": "Congestão nasal",
    "s_2307": "Congestão nasal aguda",
    "s_1812": "Congestão nasal crônica",
    "s_2308": "Congestão nasal subaguda",
    "s_2226": "Congestão nasal, piora após melhorar brevemente",
    "s_524": "Pólipos nasais",
    "s_1303": "Fala nasal",
    "s_156": "Náusea",
    "s_2512": "Náusea, desencadeada por movimento",
    "s_1388": "Assimetria do pescoço",
    "s_1483": "Dor no pescoço",
    "s_2173": "Dor no pescoço durante o movimento da cabeça",
    "s_965": "Dor no pescoço unilateral",
    "s_671": "Distensão da veia do pescoço",
    "s_820": "Negligenciar deveres e hobbies devido ao álcool",
    "s_326": "Negligenciar deveres e hobbies devido ao sexo",
    "s_2080": "Déficit neurológico, desde menos de 24 horas",
    "s_2083": "Déficit neurológico, há mais de 24 horas",
    "s_217": "Suores noturnos",
    "s_1422": "Descarga mamilar",
    "s_2258": "Nenhum contato com caso COVID-19",
    "s_2462": "Sem história de doença inflamatória intestinal",
    "s_2172": "Sem sinais de infecção respiratória superior",
    "s_2813": "Sem anormalidades de cheiro ou sabor",
    "s_182": "Noctúria",
    "s_2541": "Emissão noturna",
    "s_300": "Nódulo localizado na mama",
    "s_822": "Consumo ininterrupto de álcool por vários dias",
    "s_1217": "Sangramento ou manchas vaginais não menstruais",
    "s_2168": "Temperatura corporal normal",
    "s_2169": "Frequência cardíaca normal",
    "s_2171": "Sons pulmonares normais na ausculta",
    "s_2590": "Cor normal da urina",
    "s_2174": "Sinais vitais normais",
    "s_1605": "Dor no nariz",
    "s_1429": "Sangramento nasal",
    "s_2017": "Sangramento nasal recorrente",
    "s_2016": "Sangramento nasal, episódio único",
    "s_1148": "Dormência da língua",
    "s_377": "Numerosos nevos",
    "s_479": "Nistagmo",
    "s_1721": "Nistagmo horizontal",
    "s_127": "Obsessões",
    "s_687": "Obsessões, pensamentos compulsivos-obsessivos",
    "s_688": "Obsessões, pensamentos intrusivos",
    "s_1237": "Rotação do bulbo ocular lateralmente e para baixo",
    "s_750": "Distúrbio da motilidade ocular",
    "s_1147": "Paralisia do nervo oculomotor",
    "s_509": "Odinofagia",
    "s_362": "Pele oleosa",
    "s_1877": "Oligomenorreia",
    "s_185": "Oligúria",
    "s_1107": "Membrana timpânica opacificada",
    "s_389": "Comedões abertos",
    "s_1256": "Sinal de Oppenheim",
    "s_1410": "Neovascularização do disco óptico",
    "s_1329": "Palidez do disco do nervo óptico",
    "s_2598": "Bolhas orais",
    "s_694": "Lesões orais",
    "s_2422": "Lesões orais, alterações na localização, tamanho ou forma",
    "s_2417": "Lesões orais maiores que 1 cm",
    "s_2538": "Lesões orais, com duração de 3 ou mais semanas",
    "s_2537": "Lesões orais, com duração inferior a 3 semanas",
    "s_2414": "Lesões orais múltiplas",
    "s_2535": "Lesões orais dolorosas",
    "s_2424": "Lesões orais recorrentes",
    "s_2416": "Lesões orais, vermelhas",
    "s_2532": "Lesões orais, únicas",
    "s_2533": "Lesões orais menores que 1 cm",
    "s_2553": "Lesões orais relacionadas a trauma",
    "s_2413": "Lesões orais, bordas bem demarcadas",
    "s_1673": "Lesões orais, placas brancas",
    "s_868": "Espasmo do músculo orbicular da boca",
    "s_844": "Causa orgânica",
    "s_541": "Hipotensão ortostática",
    "s_1719": "Nós Osler",
    "s_2601": "Outra causa de queimadura",
    "s_2257": "Outro tipo de contato com caso COVID-19",
    "s_1289": "Aumento ovariano",
    "s_1110": "Sobremordida",
    "s_371": "Excesso de trabalho",
    "s_1180": "Dor atrás da orelha",
    "s_1382": "Dor causada por pressão firme na tíbia",
    "s_1453": "Dor em ambos os membros inferiores",
    "s_2092": "Dor em ambos os membros inferiores, ambos os pés",
    "s_1112": "Dor em ambos os membros inferiores, distribuição das meias",
    "s_1452": "Dor em ambos os membros superiores",
    "s_2091": "Dor em ambos os membros superiores, ambas as mãos",
    "s_2402": "Dor na frente da orelha",
    "s_579": "Dor no membro inferior",
    "s_1620": "Dor no membro inferior, panturrilha",
    "s_1927": "Dor no membro inferior, mancando devido à dor",
    "s_1930": "Dor nos membros inferiores, intensa",
    "s_2335": "Dor no membro inferior, canela",
    "s_1893": "Dor no membro inferior, coxa",
    "s_430": "Dor na articulação temporomandibular durante o movimento",
    "s_580": "Dor no membro superior",
    "s_1799": "Dor no membro superior, antebraço",
    "s_554": "Dor no membro superior, mão ou dedos",
    "s_2636": "Dor no membro superior, com duração de 1 a 7 dias",
    "s_2637": "Dor no membro superior, com duração entre 1 semana e 3 meses",
    "s_2624": "Dor no membro superior, com duração inferior a 24 horas",
    "s_2625": "Dor no membro superior, com duração superior a 3 meses",
    "s_1313": "Dor no membro superior, um dedo",
    "s_1935": "Dor no membro superior, intensa",
    "s_54": "Dor perto da órbita ocular",
    "s_1393": "Defecação dolorosa",
    "s_33": "Ejaculação dolorosa",
    "s_39": "Micção dolorosa",
    "s_32": "Relações vaginais dolorosas",
    "s_1371": "Petéquias palatinas",
    "s_10": "Unhas claras e quebradiças",
    "s_569": "Fezes claras ou cor de argila",
    "s_9": "Pele pálida",
    "s_2282": "Pele pálida, dedos",
    "s_110": "Palpitações",
    "s_2002": "Palpitações com duração superior a 5 minutos",
    "s_2004": "Palpitações recorrentes",
    "s_803": "Papiledema",
    "s_838": "Pensamento paralógico",
    "s_1496": "Sensibilidade dos seios paranasais",
    "s_2206": "Parafimose",
    "s_1486": "Sensibilidade muscular paraespinhal",
    "s_744": "Paresia ascendente",
    "s_2326": "Paresia, ambos os membros inferiores",
    "s_2343": "Paresia, ambos os membros superiores",
    "s_1005": "Paresia, hemiparesia",
    "s_148": "Paresia, membros",
    "s_2346": "Paresia, membros, durando entre 24 horas e 4 semanas",
    "s_2345": "Paresia, membros, com duração inferior a 24 horas",
    "s_2347": "Paresia, membros, com duração superior a 4 semanas",
    "s_2340": "Paresia, membros, outros",
    "s_2339": "Paresia, membros, tetraparesia",
    "s_2331": "Paresia localizada",
    "s_2318": "Paresia, um membro inferior",
    "s_2342": "Paresia, um membro superior",
    "s_518": "Parestesia",
    "s_1066": "Parestesia em ambos os membros superiores, distribuição de luva",
    "s_126": "Parestesia ao redor da boca",
    "s_1069": "Parestesia ascendente",
    "s_125": "Parestesia, ambos os pés",
    "s_1063": "Parestesia, ambos os membros inferiores",
    "s_973": "Parestesia, ambos os membros superiores",
    "s_971": "Parestesia, rosto",
    "s_2306": "Parestesia, membros inferiores",
    "s_2315": "Parestesia, um pé",
    "s_2001": "Parestesia, um membro inferior",
    "s_1449": "Parestesia, um membro superior",
    "s_1932": "Parestesia, início súbito",
    "s_2304": "Parestesia, membros superiores",
    "s_1068": "Parestesias simétricas",
    "s_529": "Inchaço da parótida",
    "s_1800": "Apendicectomia anterior",
    "s_1715": "Fraturas patológicas",
    "s_795": "Banquinhos finos como lápis",
    "s_2043": "Coceira peniana",
    "s_1380": "Fricção pericárdica",
    "s_1312": "Dor perioníquica",
    "s_2084": "Equimose periorbital",
    "s_1018": "Perseverança",
    "s_348": "Mudanças de personalidade",
    "s_594": "Petéquias",
    "s_1367": "Eritema faríngeo",
    "s_20": "Dor faríngea",
    "s_1826": "Dor faríngea unilateral",
    "s_2461": "Edema faríngeo",
    "s_263": "Fimose",
    "s_2088": "Lesão física",
    "s_281": "Lesão física, para si mesmo ou para terceiros após beber álcool",
    "s_1437": "Derrame pleural",
    "s_1396": "Fricção pleural",
    "s_2112": "Dor torácica pleurítica, após trauma",
    "s_75": "Poliúria",
    "s_1093": "Desafio de alergia positivo",
    "s_1092": "Teste de alergia cutânea por picada ou adesivo positivo",
    "s_2609": "Teste de mordida de lâmina de língua positivo",
    "s_2175": "Possível exposição à raiva",
    "s_1471": "Gotejamento pós-nasal",
    "s_225": "Envelhecimento prematuro",
    "s_69": "Preocupação com comida",
    "s_1323": "Presença de neurofibromas",
    "s_1162": "Mixedema pré-tibial",
    "s_1397": "Priapismo",
    "s_593": "Tempo de sangramento prolongado",
    "s_1508": "Tempo de recarga capilar prolongado",
    "s_777": "Sono noturno prolongado",
    "s_1089": "Aumento da próstata",
    "s_254": "Prurido",
    "s_251": "Prurido, agravado pela mudança de temperatura, suor ou uso de lã",
    "s_2033": "Prurido no pé",
    "s_250": "Prurido, mais intenso à noite",
    "s_1617": "Prurido no couro cabeludo",
    "s_1548": "Pseudomembrana no nariz ou garganta",
    "s_2753": "Distanciamento psicológico dos estudos ou do trabalho",
    "s_649": "Retardo psicomotor",
    "s_653": "Variação da dilatação da pupila",
    "s_1238": "Aluno ou alunos que não reagem à acomodação",
    "s_762": "Erupção purpúrica",
    "s_1915": "Erupção purpúrica, palpável",
    "s_2212": "Corrimento vaginal purulento",
    "s_1315": "Pus sob a pele do dedo",
    "s_2717": "Pus sob a pele do dedo do pé",
    "s_388": "Pústula",
    "s_989": "Sinais piramidais",
    "s_637": "Aceleração de pensamentos e fala",
    "s_779": "Decisões precipitadas ou impulsivas",
    "s_2105": "Revivenciando traumas, sintomas angustiantes",
    "s_2097": "Revivenciando traumas, sintomas intrusivos",
    "s_2351": "Dificuldades de leitura",
    "s_2793": "Comportamento rebelde",
    "s_1494": "Sensibilidade de recuperação",
    "s_528": "Consumo recente de álcool",
    "s_2079": "Lesão recente sem sangramento",
    "s_2715": "Heteroagressividade física recente, com risco de saúde ou de vida",
    "s_2230": "Comportamento autolesivo recente, grave",
    "s_142": "Úlceras gástricas recorrentes",
    "s_139": "Inflamação recorrente das amígdalas",
    "s_72": "Conjuntivas vermelhas e ardorosas",
    "s_692": "Mucosa oral vermelha e inchada",
    "s_112": "Banquinho de cor vermelha",
    "s_2819": "Fezes vermelhas, sangramento intenso",
    "s_2818": "Fezes vermelhas, sangramento leve",
    "s_113": "Urina de cor vermelha",
    "s_492": "Olho vermelho",
    "s_2524": "Olhos vermelhos, bilaterais",
    "s_2577": "Olhos vermelhos, unilaterais",
    "s_384": "Máculas vermelhas com escamas branco-prateadas e escamosas",
    "s_73": "Estrias vermelhas no abdômen, quadris ou coxas",
    "s_1181": "Vermelhidão atrás da orelha",
    "s_1145": "Vermelhidão na região temporal",
    "s_410": "Temperatura corporal reduzida",
    "s_572": "Grau reduzido de expressão facial",
    "s_1713": "Amplitude reduzida de movimento articular",
    "s_1903": "Amplitude reduzida de movimento articular, passiva",
    "s_2008": "Contrações uterinas regulares",
    "s_330": "Regurgitação",
    "s_2076": "Desconforto respiratório, após trauma",
    "s_1463": "Pernas inquietas, noturnas",
    "s_2764": "Inquietação",
    "s_2766": "Inquietação, 3 dias ou menos",
    "s_2767": "Inquietação, 4 a 6 dias",
    "s_2769": "Inquietação, 6 meses ou mais",
    "s_2768": "Inquietação, entre 7 dias e 6 meses",
    "s_1697": "Interesses restritos",
    "s_186": "Mobilidade restrita, dificuldade de curvar-se",
    "s_1418": "Oclusões da artéria retiniana",
    "s_1344": "Hemorragia retiniana",
    "s_1412": "Microaneurismas retinais",
    "s_1415": "Neovascularização da retina em outro lugar",
    "s_1414": "Inchaço da retina",
    "s_343": "Retração ou recuo do mamilo",
    "s_1055": "Neurite retrobulbar",
    "s_1533": "Rinite desencadeada pelo ambiente",
    "s_1682": "Rinofima",
    "s_816": "Consumo arriscado de álcool",
    "s_1738": "Sinal de Romberg",
    "s_1377": "Manchas rosadas na parte inferior do tórax e abdômen",
    "s_1255": "Sinal de Rossolimo",
    "s_1732": "Sinal de Rovsing",
    "s_1141": "Sinal de Russell",
    "s_1285": "Flacidez do palato ipsilateral à suspeita de lesão nervosa",
    "s_1544": "Erupção cutânea escarlatiniforme",
    "s_1259": "Sinal de Schaeffer",
    "s_836": "Esquizofasia",
    "s_1492": "Icterícia escleral",
    "s_124": "Escotoma",
    "s_829": "Consumo secreto de álcool",
    "s_2045": "Convulsões",
    "s_2209": "Convulsões recorrentes",
    "s_2259": "Convulsões, reflexo",
    "s_189": "Convulsões, com perda de consciência",
    "s_539": "Convulsões, sem perda de consciência",
    "s_2228": "Comportamento autolesivo, recente",
    "s_2213": "Pensamentos ou comportamentos autolesivos",
    "s_123": "Autonegligência",
    "s_2755": "Sensação de ineficácia",
    "s_423": "Senso de autoestima dependente do peso",
    "s_972": "Perda sensorial em ambos os braços",
    "s_313": "Perda sensorial em um membro",
    "s_2702": "Perda sensorial em um membro, durando entre 24 horas e 4 semanas",
    "s_2696": "Perda sensorial em um membro, com duração inferior a 24 horas",
    "s_2703": "Perda sensorial em um membro, com duração superior a 4 semanas",
    "s_2089": "Lesão grave, dificuldade respiratória, sangramento intenso, novo problema neurológico",
    "s_2081": "Dor intensa, após trauma",
    "s_2643": "Atividade sexual",
    "s_74": "Atividade sexual com mais frequência ou com mais parceiros do que o pretendido",
    "s_1399": "Sinal de Shafer",
    "s_1462": "Respiração superficial",
    "s_2274": "Baixa estatura",
    "s_648": "Paradelírios auditivos simples",
    "s_647": "Desilusões visuais simples",
    "s_2356": "Área vermelha única, maior que 1 cm",
    "s_2358": "Mancha vermelha única, menor que 1 cm",
    "s_1470": "Descamação da pele",
    "s_506": "Marca na pele causada por picada de inseto ou inseto",
    "s_1831": "Massa cutânea",
    "s_1931": "Massa cutânea, sangramento",
    "s_1830": "Massa cutânea maior que 1 cm",
    "s_2550": "Massa cutânea, maior que 1 cm, firme",
    "s_2549": "Massa cutânea, maior que 1 cm, macia",
    "s_2555": "Massa cutânea maior que 1 cm, consistência desconhecida",
    "s_2684": "Massa cutânea localizada no pulso",
    "s_2285": "Massa cutânea, fenda natal",
    "s_101": "Massa cutânea menor que 1 cm",
    "s_2668": "Massa cutânea, transiluminável",
    "s_2661": "Massa cutânea, com ponto central",
    "s_1674": "Dor na pele",
    "s_2619": "Dor na pele associada a nadar no mar ou oceano",
    "s_43": "Dor na pele, intensa",
    "s_1209": "Palidez da pele repentina",
    "s_376": "Fototipo de pele I ou II",
    "s_1650": "Espessamento da pele",
    "s_2377": "Ferida na pele",
    "s_1973": "Ferida na pele, sangramento ativo",
    "s_2398": "Ferida na pele, após cirurgia",
    "s_2785": "Ferida na pele, mordida de animal",
    "s_2599": "Ferida na pele, sangramento controlável",
    "s_2077": "Ferida na pele, sangramento grave",
    "s_2078": "Ferida na pele, pequeno sangramento incontrolável",
    "s_2379": "Ferida na pele, úlcera não traumática",
    "s_2399": "Ferida na pele, autoinfligida",
    "s_2378": "Ferida na pele relacionada a trauma",
    "s_1474": "Lesões no crânio",
    "s_131": "Ataques de sono",
    "s_319": "Distúrbio do sono",
    "s_238": "Marcha mais lenta",
    "s_196": "Fumar, durante a doença",
    "s_265": "Fumar, em áreas para não fumantes",
    "s_93": "Fumar, grandes quantidades",
    "s_308": "Fumar, principalmente pela manhã",
    "s_134": "Fumar, preciso do primeiro cigarro do dia",
    "s_327": "Fumar, preciso ao acordar",
    "s_271": "Rosto liso sem rugas",
    "s_2584": "Ferida por picada de cobra",
    "s_715": "Espirros",
    "s_136": "Ataques de espirros",
    "s_67": "Ronco",
    "s_659": "Isolamento social",
    "s_773": "Desajuste social",
    "s_1242": "Nódulo mole na região inguinal",
    "s_1244": "Nódulo mole na região inguinal, diminuindo quando em posição supina",
    "s_1243": "Nódulo mole na região inguinal, aumentando com o aumento da pressão abdominal",
    "s_1245": "Nódulo mole na região inguinal, redutível",
    "s_1347": "Exsudados retinais moles",
    "s_1426": "Espasmos nas extremidades superiores",
    "s_571": "Espasticidade",
    "s_1603": "Comprometimento de fala e linguagem, atraso no desenvolvimento",
    "s_2290": "Comprometimento de fala e linguagem, início rápido",
    "s_213": "Passar muito tempo em atividades relacionadas ao sexo",
    "s_2594": "Picada de aranha, não venenosa",
    "s_2593": "Picada de aranha, possivelmente venenosa",
    "s_2588": "Picada de aranha, tipo desconhecido",
    "s_1536": "Assimetria da coluna",
    "s_257": "Rigidez da coluna pela manhã",
    "s_1045": "Esplenomegalia",
    "s_1434": "Esteatorreia",
    "s_1270": "Sinal de Sterling",
    "s_1381": "Massagem esternal",
    "s_418": "Pescoço rígido",
    "s_1260": "Sinal de Stransky",
    "s_1467": "Língua de morango",
    "s_1275": "Sinal de Strumpell",
    "s_2614": "Hematoma sublingual",
    "s_2291": "Hemorragias subungueais em estilhaços",
    "s_1067": "Rubor facial repentino",
    "s_530": "Aumento repentino da pressão arterial",
    "s_1140": "Intenção suicida",
    "s_1139": "Pensamentos suicidas",
    "s_1943": "Queimadura solar",
    "s_2407": "Suspeita de intoxicação ou envenenamento",
    "s_383": "Toupeira ou marca de nascença suspeita",
    "s_380": "Toupeira ou marca de nascença suspeita, assimétrica",
    "s_585": "Toupeira ou marca de nascença suspeita, mudança de cor",
    "s_584": "Toupeira ou marca de nascença suspeita, mudança de forma",
    "s_381": "Toupeira ou marca de nascença suspeita, borda irregular",
    "s_382": "Pinta ou marca de nascença suspeita, maior que 6 mm",
    "s_2300": "Corpo estranho engolido",
    "s_214": "Suor no lado dolorido da testa",
    "s_1182": "Inchaço na região mastóide",
    "s_1099": "Inchaço da concha nasal",
    "s_1097": "Inchaço da mucosa nasal",
    "s_1144": "Inchaço da artéria temporal",
    "s_1098": "Mucosa nasal azulada ou cinza-azulada inchada",
    "s_177": "Bochecha inchada",
    "s_172": "Gengivas inchadas",
    "s_558": "Sintomas agravados pela baixa temperatura",
    "s_161": "Sintomas que aparecem regularmente alguns dias antes do período menstrual",
    "s_261": "Taquicardia",
    "s_227": "Taquipneia",
    "s_1153": "Distúrbio do paladar, dois terços anteriores da língua",
    "s_1096": "Distúrbios do paladar",
    "s_545": "Telangiectasias",
    "s_1685": "Telangiectasias, rosto",
    "s_1465": "Cegueira temporária",
    "s_2410": "Ternura no escroto",
    "s_2606": "Sensibilidade da coluna",
    "s_640": "Tenesmo",
    "s_1114": "As unhas de Terry",
    "s_1117": "Atrofia testicular",
    "s_1790": "Unhas espessadas",
    "s_386": "Unhas espessadas, com buracos ou ranhuras",
    "s_2039": "Queimadura de terceiro grau",
    "s_728": "Pensamentos de prejudicar o filho",
    "s_729": "Pensamentos de matar o filho",
    "s_555": "Dedos formigando",
    "s_1899": "Dedos formigando, primeiros três dígitos",
    "s_1900": "Dedos formigando, quarto e quinto dígitos",
    "s_407": "Zumbido",
    "s_1466": "Zumbido pulsátil",
    "s_1833": "Dor no dedo do pé",
    "s_673": "Aumento da língua",
    "s_607": "Eritema da língua",
    "s_995": "Atrofia muscular da língua",
    "s_1534": "Pedras de amígdalas",
    "s_1050": "Perda dentária",
    "s_2514": "Migração dentária",
    "s_551": "Dor de dente",
    "s_56": "Dor de dente associada à mastigação",
    "s_2432": "Dor de dente associada ao resfriado",
    "s_231": "Dor de dente associada ao calor",
    "s_2451": "Dor de dente associada a deitar",
    "s_2452": "Dor de dente associada a alimentos ou bebidas doces ou ácidas",
    "s_2485": "Dor de dente associada ao toque",
    "s_2491": "Dor de dente, dificuldade em determinar quantos dentes doem",
    "s_267": "Dor de dente, dificuldade em determinar qual dente dói",
    "s_2565": "Dor de dente devido a trauma",
    "s_2438": "Dor de dente, sem graça",
    "s_2450": "Dor de dente, com duração de 48 horas ou mais",
    "s_2456": "Dor de dente, com duração inferior a 48 horas",
    "s_2429": "Dor de dente leve",
    "s_2428": "Dor de dente moderada",
    "s_2441": "Dor de dente, vários dentes",
    "s_2442": "Dor de dente, um dente",
    "s_2449": "Dor de dente persistente",
    "s_2430": "Dor de dente provocada",
    "s_2443": "Dor de dente, pulsante",
    "s_2446": "Dor de dente irradiando",
    "s_2545": "Dor de dente, sensibilidade à percussão",
    "s_233": "Dor de dente intensa",
    "s_2440": "Dor de dente aguda",
    "s_2431": "Dor de dente espontânea",
    "s_2433": "Dor de dente transitória",
    "s_1342": "Escroto transiluminável",
    "s_1100": "Sulco transversal no nariz",
    "s_2713": "Distúrbios relacionados a trauma ou estresse",
    "s_538": "Tremores",
    "s_87": "Tremores, ambas as mãos",
    "s_2608": "Tremores, ambas as pernas",
    "s_2646": "Tremores, em ambas as mãos",
    "s_2647": "Tremores, em qualquer perna",
    "s_1421": "Tremores, agitando-se",
    "s_85": "Tremores, cabeça",
    "s_1264": "Tremores, intenção",
    "s_1454": "Tremores cinéticos",
    "s_86": "Tremores, uma mão",
    "s_2611": "Tremores, uma perna",
    "s_1379": "Tremores, rolagem de comprimidos",
    "s_1455": "Tremores, repouso",
    "s_1765": "Tremores relacionados ao estresse",
    "s_84": "Tremores, desaparecem depois de beber álcool",
    "s_590": "Tremores, língua",
    "s_1056": "Neuralgia do trigêmeo",
    "s_256": "Trismo",
    "s_1262": "Sinal de Tromner",
    "s_1074": "Mudanças nos troféus",
    "s_1076": "Úlcera trófica",
    "s_1078": "Úlcera trófica, lado plantar do pé",
    "s_691": "Dificuldade para começar a urinar",
    "s_866": "Sinal de enxoval de tetania latente",
    "s_2163": "Pescoço torcido",
    "s_2119": "Úlcera, completamente indolor",
    "s_194": "Úlcera, mamilo",
    "s_2208": "Úlcera que não cicatriza em 6 semanas",
    "s_351": "Úlcera, ferida ou abscesso perto do ânus",
    "s_2241": "Descarga umbilical",
    "s_243": "Práticas pouco saudáveis ​​de perda de peso",
    "s_668": "Enoftalmia unilateral",
    "s_1586": "Edema vulvar unilateral",
    "s_2251": "Frequência cardíaca desconhecida",
    "s_2604": "Cor de urina desconhecida",
    "s_2": "Relações vaginais desprotegidas nos últimos 3 meses",
    "s_2068": "Palidez dos membros superiores",
    "s_1619": "Sensibilidade nos membros superiores",
    "s_510": "Corrimento uretral ao pressionar o pênis",
    "s_153": "Incontinência urinária",
    "s_696": "Retenção urinária",
    "s_690": "Urgência urinária",
    "s_689": "Micção em pequenas quantidades",
    "s_183": "Urinar ao tossir, rir ou durante esforço físico",
    "s_393": "Urticária",
    "s_2211": "Urticária, com duração superior a 6 semanas",
    "s_791": "Urticária, desencadeada pelo frio",
    "s_793": "Urticária, desencadeada pelo calor",
    "s_790": "Urticária, desencadeada por pressão prolongada na pele",
    "s_789": "Urticária, desencadeada por coçar",
    "s_1287": "Desvio da úvula para o lado não afetado",
    "s_152": "Sangramento vaginal após relação sexual",
    "s_2338": "Corrimento vaginal, cinza",
    "s_64": "Corrimento vaginal, odor desagradável",
    "s_1569": "Corrimento vaginal, branco e grosso",
    "s_1811": "Secura vaginal",
    "s_2082": "Comprometimento vascular",
    "s_2270": "Exantema vesicular do ouvido",
    "s_2269": "Exantema vesicular dos lábios ou pele perioral",
    "s_824": "Violar normas sociais depois de beber álcool",
    "s_1792": "Piolhos visíveis no couro cabeludo ou na escova",
    "s_1034": "Agnosia visual",
    "s_494": "Estreitamento do campo visual",
    "s_1398": "Hemorragia vítrea",
    "s_305": "Vômito",
    "s_2057": "Vômito, 7 dias ou mais",
    "s_2055": "Vômito, bilioso",
    "s_1949": "Vômito, sempre após as refeições",
    "s_2658": "Vômito, comida",
    "s_2056": "Vômito, menos de 7 dias",
    "s_1365": "Vômito, mais frequentemente pela manhã",
    "s_801": "Vômito, projétil",
    "s_1875": "Queima vulvovaginal",
    "s_630": "Comichão vulvovaginal",
    "s_2107": "Acordar durante a noite",
    "s_776": "Acordar cedo",
    "s_1280": "Reflexo faríngeo fraco ou ausente",
    "s_1348": "Pulsos periféricos fracos",
    "s_735": "Pulso fraco nas pernas",
    "s_2142": "Peso vacilante",
    "s_226": "Ganho de peso",
    "s_285": "Perda de peso",
    "s_237": "Perda de peso, sem perda de apetite",
    "s_1402": "Anel Weiss",
    "s_1340": "Sinal de Westphal",
    "s_2166": "Chiado agudo",
    "s_1562": "Chiado, estridor",
    "s_1788": "Descoloração branca das unhas",
    "s_1796": "Mancha branca na córnea",
    "s_1795": "Tocas branco-acinzentadas na pele",
    "s_1183": "Sinal de Winterbottom",
    "s_1937": "Vermes nas fezes",
    "s_1981": "Infecção de feridas",
    "s_2222": "Ferida, lábio",
    "s_342": "Enrugamento ou ondulação da pele do peito",
    "s_1910": "Queda de pulso",
    "s_2344": "Dificuldades de escrita",
    "s_613": "Xantelasma",
    "s_1561": "Xantoma",
    "s_1789": "Descoloração amarela das unhas",
    "s_2519": "Banqueta de cor amarela ou verde",
    "p_144": "Lesão abdominal",
    "p_330": "Ausência de vacinação contra COVID-19",
    "p_145": "Lesão por aceleração-desaceleração",
    "p_155": "Mordida de animal",
    "p_390": "Mordida de animal, cobra",
    "p_243": "Lesão no tornozelo",
    "p_174": "Uso de anticoagulante",
    "p_254": "Lesão no braço",
    "p_81": "Mudanças na pressão atmosférica",
    "p_146": "Lesão nas costas",
    "p_79": "Amigdalite bacteriana",
    "p_359": "Injeção de reforço da vacinação contra COVID-19",
    "p_281": "Amamentação",
    "p_295": "Desenvolvimento dos seios",
    "p_360": "Vacinação contra COVID-19, há mais de 6 meses",
    "p_410": "Ingestão de canabinóides, últimas 72 horas",
    "p_69": "Fumar cannabis, últimas 72 horas",
    "p_40": "Exposição ao monóxido de carbono",
    "p_136": "Lesão no peito",
    "p_220": "Uso crônico de AINEs",
    "p_511": "Uso crônico de canabinóides",
    "p_393": "Exposição crônica a pesticidas ou produtos químicos à base de benzeno",
    "p_38": "Uso crônico de álcool pesado",
    "p_283": "Circuncisão",
    "p_83": "Sistema imunológico comprometido",
    "p_380": "Consumo de alimentos ou bebidas que causam inchaço",
    "p_303": "Consumo de frutas ou vegetais contaminados com fezes de animais",
    "p_63": "Consumo de carne crua ou mal cozida",
    "p_139": "Lentes de contato",
    "p_82": "Contato com alérgeno",
    "p_122": "Contato com animais",
    "p_100": "Contato com animais, gato",
    "p_500": "Contato com crianças com gastroenterite",
    "p_99": "Contato com pessoa doente, varicela",
    "p_508": "Contato com pessoa doente, varíola dos macacos",
    "p_385": "Contato com o sangue de outra pessoa",
    "p_188": "Trauma dentário",
    "p_93": "Doença de Crohn diagnosticada",
    "p_182": "Aneurisma da aorta diagnosticado",
    "p_167": "Asma diagnosticada",
    "p_173": "Aterosclerose diagnosticada",
    "p_342": "Fibrilação atrial diagnosticada",
    "p_60": "Colecistolitíase diagnosticada",
    "p_441": "Insuficiência cardíaca crônica diagnosticada",
    "p_164": "Doença pulmonar obstrutiva crônica diagnosticada",
    "p_306": "Concussão diagnosticada",
    "p_78": "Doença coronariana diagnosticada",
    "p_8": "Diabetes diagnosticado",
    "p_370": "Diabetes mellitus diagnosticado, tipo 1",
    "p_371": "Diabetes mellitus diagnosticado, tipo 2",
    "p_378": "Diabetes diagnosticado, outro tipo",
    "p_379": "Diabetes diagnosticado, tipo desconhecido",
    "p_530": "Anemia hemolítica diagnosticada",
    "p_202": "Hemofilia diagnosticada",
    "p_9": "Hipertensão diagnosticada",
    "p_333": "Osteoporose ou osteopenia diagnosticada",
    "p_386": "Massa ovariana diagnosticada",
    "p_183": "Doença vascular periférica diagnosticada",
    "p_263": "Psoríase diagnosticada",
    "p_121": "Doença cardíaca estrutural diagnosticada",
    "p_358": "Colite ulcerativa diagnosticada",
    "p_549": "Consumo de drogas, novo nas últimas 4 semanas",
    "p_209": "Lesão no ouvido",
    "p_253": "Lesão no cotovelo",
    "p_364": "Cicatriz ampliada",
    "p_128": "Exposição excessiva aos raios UV",
    "p_84": "Consumo excessivo de álcool, últimas 12 horas",
    "p_361": "Uso excessivo de terminais de exibição visual",
    "p_355": "História familiar de infecção por Helicobacter pylori",
    "p_272": "História familiar de distúrbios hemorrágicos",
    "p_169": "Lesão no dedo",
    "p_414": "Picada de mosca, Austrália e Oceania",
    "p_478": "Picada de mosca, África Central",
    "p_418": "Picada de mosca, América Central ou do Sul",
    "p_419": "Picada de mosca, Europa",
    "p_435": "Picada de mosca, América do Norte",
    "p_477": "Picada de mosca, Norte da África",
    "p_436": "Picada de mosca, Norte da Ásia",
    "p_437": "Picada de mosca, Sudeste Asiático",
    "p_479": "Picada de mosca, África Austral",
    "p_417": "Picada de mosca, Oriente Médio",
    "p_246": "Lesão no pé",
    "p_252": "Lesão no antebraço",
    "p_528": "Corpo estranho em ferida",
    "p_267": "Exposição frequente à fumaça do tabaco",
    "p_332": "Vacinação completa contra COVID-19",
    "p_207": "Lesão genital",
    "p_250": "Lesão na mão",
    "p_260": "Lesão na área da cabeça",
    "p_353": "Exposição a metais pesados ​",
    "p_10": "Colesterol alto",
    "p_204": "Alto risco de gravidez ectópica",
    "p_245": "Lesão no quadril",
    "p_318": "História de infecção por COVID",
    "p_509": "História de situação estressante, nos últimos 6 meses",
    "p_176": "História de reação anafilática",
    "p_384": "História de queimadura",
    "p_296": "História de vacinação contra varicela ou VZV",
    "p_397": "História de colecistectomia",
    "p_311": "História de colectomia",
    "p_319": "História da coronarografia",
    "p_313": "História de cistectomia",
    "p_201": "História de trombose venosa profunda",
    "p_171": "História de episódio depressivo",
    "p_490": "História de inserção de corpo estranho no ouvido",
    "p_491": "História de inserção de corpo estranho no nariz",
    "p_312": "História de gastrectomia",
    "p_307": "História de traumatismo cranioencefálico",
    "p_320": "História de cirurgia de ponte de safena",
    "p_324": "História de cirurgia de hérnia",
    "p_321": "História de substituição da articulação do quadril",
    "p_327": "História de crise hipertensiva",
    "p_276": "História de histerectomia",
    "p_322": "História de substituição da articulação do joelho",
    "p_325": "História de laringectomia",
    "p_159": "História de lesão cutânea grave",
    "p_178": "História de neoplasia maligna",
    "p_442": "História de lesões marítimas",
    "p_309": "História de mastectomia",
    "p_52": "História de lesão cutânea leve",
    "p_314": "História de nefrectomia",
    "p_196": "História de nefrolitíase",
    "p_315": "História de orquiectomia",
    "p_317": "História de polipectomia",
    "p_316": "História de prostatectomia",
    "p_344": "História de tuberculose pulmonar",
    "p_310": "História de pneumectomia",
    "p_90": "História de febre reumática",
    "p_323": "História de sepse",
    "p_278": "História de acidente vascular cerebral",
    "p_308": "História de tireoidectomia",
    "p_190": "História de amigdalectomia",
    "p_326": "História de vasectomia",
    "p_54": "Contraceptivo hormonal ou terapia hormonal da menopausa",
    "p_468": "Picada de Hymenoptera, Austrália e Oceania",
    "p_484": "Picada de Hymenoptera, África Central",
    "p_463": "Picada de Hymenoptera, América Central e do Sul",
    "p_464": "Picada de Hymenoptera, Europa",
    "p_467": "Picada de Hymenoptera, Oriente Médio",
    "p_462": "Picada de Hymenoptera, América do Norte",
    "p_483": "Picada de Hymenoptera, Norte da África",
    "p_465": "Picada de Hymenoptera, Norte da Ásia",
    "p_466": "Picada de Hymenoptera, Sudeste Asiático",
    "p_485": "Picada de Hymenoptera, África Austral",
    "p_367": "Higiene inadequada das mãos",
    "p_357": "Higiene bucal inadequada",
    "p_331": "Vacinação incompleta contra COVID-19",
    "p_536": "Vacinação contra tétano incompleta ou falta",
    "p_389": "Herança indígena australiana",
    "p_149": "Instituição de terapia com inibidor da ECA",
    "p_49": "Uso de drogas intravenosas",
    "p_434": "Picada de inseto beijador, América Central ou do Sul",
    "p_424": "Picada de inseto beijador, América do Norte",
    "p_244": "Lesão no joelho",
    "p_208": "Última imunização contra tétano há mais de 5 anos",
    "p_53": "Lesão nos membros",
    "p_282": "Lesão labial",
    "p_191": "Compressão local do membro superior",
    "p_506": "Contato prolongado com solo úmido",
    "p_6": "IMC baixo",
    "p_247": "Lesão na perna",
    "p_256": "Lesão nos membros inferiores",
    "p_261": "Lesão por força grave",
    "p_157": "Imaturidade sexual masculina",
    "p_2": "Homem",
    "p_496": "Homem que não revela suas preferências sexuais",
    "p_493": "Homem que faz sexo com homens",
    "p_494": "Homem que faz sexo com mulheres",
    "p_499": "Homem que faz sexo com mulheres e homens",
    "p_375": "Consumo materno de álcool durante a gravidez",
    "p_292": "Vacina obrigatória perdida",
    "p_432": "Picada de mosquito, Austrália e Oceania",
    "p_472": "Picada de mosquito, África Central",
    "p_427": "Picada de mosquito, América Central ou do Sul",
    "p_430": "Picada de mosquito, Europa",
    "p_428": "Picada de mosquito, América do Norte",
    "p_471": "Picada de mosquito, Norte da África",
    "p_438": "Picada de mosquito, Norte da Ásia",
    "p_429": "Picada de mosquito, Sudeste Asiático",
    "p_473": "Picada de mosquito, África Austral",
    "p_431": "Picada de mosquito, Oriente Médio",
    "p_229": "Lesão nas unhas",
    "p_240": "Lesão no pescoço",
    "p_334": "Nova atividade física intensiva",
    "p_488": "Nenhum parto nas últimas 6 semanas",
    "p_271": "Sem lesão na pele",
    "p_449": "Nenhuma suspeita de abuso",
    "p_166": "Ferida que não cicatriza",
    "p_142": "Lesão ocular não grave",
    "p_185": "Lesão no nariz",
    "p_43": "Uso de opioides, últimas 72 horas",
    "p_305": "Antibióticos orais nas últimas 2 semanas",
    "p_377": "Terapia oral com ferro",
    "p_7": "Excesso de peso e obesidade",
    "p_25": "Overdose de paracetamol, últimas 72 horas",
    "p_241": "Lesão pélvica",
    "p_287": "História pessoal ou familiar de alergia",
    "p_300": "Contato físico com caso COVID-19",
    "p_301": "Possível contato com caso COVID-19",
    "p_11": "Pós-menopausa",
    "p_55": "Período pós-parto",
    "p_141": "Idade pré-menstrual",
    "p_42": "Gravidez",
    "p_152": "Gravidez, primeiro trimestre",
    "p_153": "Gravidez, II trimestre",
    "p_154": "Gravidez, III trimestre",
    "p_80": "Infarto do miocárdio prévio",
    "p_213": "Sangramento profuso recentemente",
    "p_138": "Exposição prolongada à luz solar e ao calor",
    "p_280": "Imobilização prolongada dos membros inferiores",
    "p_518": "Trauma psicológico, há menos de um mês",
    "p_519": "Trauma psicológico, há mais de um mês",
    "p_339": "Infecção recente por COVID-19",
    "p_143": "Consumo recente de álcool",
    "p_352": "Consumo recente de beterraba",
    "p_234": "Queimadura recente",
    "p_36": "Contato recente com herbicidas",
    "p_41": "Contato recente com inseticidas",
    "p_232": "Lesão recente na cabeça",
    "p_161": "História recente de infecções do trato urinário",
    "p_366": "Imunização recente",
    "p_354": "Otite média recente",
    "p_264": "Lesão física recente",
    "p_47": "Cirurgia recente ou procedimento invasivo",
    "p_148": "Infecção recente do trato respiratório superior",
    "p_236": "Residência ou viagem recente, Ásia, excluindo Médio Oriente, Rússia, Mongólia e Cazaquistão",
    "p_19": "Residência ou viagem recente, Austrália e Oceania",
    "p_17": "Residência ou viagem recente, África Central",
    "p_14": "Residência ou viagem recente, América Central ou do Sul",
    "p_15": "Residência ou viagem recente, Europa",
    "p_21": "Residência ou viagem recente, Oriente Médio",
    "p_16": "Residência ou viagem recente, Norte da África",
    "p_20": "Residência ou viagem recente, Rússia, Cazaquistão ou Mongólia",
    "p_18": "Residência ou viagem recente, África Austral",
    "p_13": "Residência ou viagem recente, Estados Unidos ou Canadá",
    "p_216": "Comportamento sexual de risco",
    "p_273": "Overdose de salicilato, últimas 72 horas",
    "p_289": "Frequência escolar",
    "p_46": "Abstinência de sedativos ou hipnóticos",
    "p_214": "Lesão ocular grave",
    "p_255": "Lesão no ombro",
    "p_45": "Uso de pílulas para dormir ou sedativos nas últimas 72 horas",
    "p_533": "Tabaco sem fumaça",
    "p_28": "Fumar cigarros",
    "p_460": "Picada de aranha, Austrália e Oceania",
    "p_481": "Picada de aranha, África Central",
    "p_455": "Picada de aranha, América Central e do Sul",
    "p_456": "Picada de aranha, Europa",
    "p_459": "Picada de aranha, Oriente Médio",
    "p_454": "Picada de aranha, América do Norte",
    "p_480": "Picada de aranha, Norte da África",
    "p_457": "Picada de aranha, Norte da Ásia",
    "p_458": "Picada de aranha, Sudeste Asiático",
    "p_482": "Picada de aranha, África Austral",
    "p_156": "Uso de estimulantes, últimas 72 horas",
    "p_248": "Lesão na coxa",
    "p_452": "Picada de carrapato, Austrália e Oceania",
    "p_475": "Picada de carrapato, África Central",
    "p_420": "Picada de carrapato, América Central ou do Sul",
    "p_421": "Picada de carrapato, Europa",
    "p_415": "Picada de carrapato, América do Norte",
    "p_476": "Picada de carrapato, norte da África",
    "p_439": "Picada de carrapato, norte da Ásia",
    "p_440": "Picada de carrapato, Sudeste Asiático",
    "p_474": "Picada de carrapato, África Austral",
    "p_422": "Picada de carrapato, Oriente Médio",
    "p_140": "Tempo gasto a mais de 2.500 metros ou 8.200 pés acima do nível do mar",
    "p_168": "Lesão no dedo do pé",
    "p_259": "Lesão no tronco",
    "p_445": "Duas doses de reforço da vacinação contra COVID-19",
    "p_61": "Dieta vegetariana desequilibrada",
    "p_448": "Incerteza sobre abuso",
    "p_443": "Homem não circuncidado",
    "p_409": "Picada de inseto desconhecida, Austrália e Oceania",
    "p_402": "Picada de inseto desconhecida, África Central",
    "p_404": "Picada de inseto desconhecida, América Central ou do Sul",
    "p_400": "Picada de inseto desconhecida, Europa",
    "p_405": "Picada de inseto desconhecida, América do Norte",
    "p_403": "Picada de inseto desconhecida, Norte da África",
    "p_407": "Picada de inseto desconhecida, Norte da Ásia",
    "p_408": "Picada de inseto desconhecida, Sudeste Asiático",
    "p_401": "Picada de inseto desconhecida, África Austral",
    "p_406": "Picada de inseto desconhecida, Oriente Médio",
    "p_230": "Marcador de condições não especificadas",
    "p_257": "Lesão no membro superior",
    "p_447": "Vítima de abuso",
    "p_335": "Vítima de abuso físico",
    "p_363": "Vítima de abuso psicológico",
    "p_376": "Sensibilidade climática",
    "p_37": "Consumo de cogumelos selvagens",
    "p_293": "Dentro de dois anos após a menarca",
    "p_1": "Mulher",
    "p_497": "Mulher que não revela suas preferências sexuais",
    "p_495": "Mulher que faz sexo com homens",
    "p_498": "Mulher que faz sexo com homens e mulheres",
    "p_492": "Mulher que faz sexo com mulheres",
    "p_251": "Lesão no pulso"
}