import React, { useEffect, useState } from "react";
import { websocket, initWebSocket, startRecording as startWebsocketRecording, stopRecording as stopWebsocketRecording } from "../utils/socket";

export default function useWebsocket() {
    const [isRecording, setIsRecording] = useState(false)
    const [transcription, setTranscription] = useState('')
    const [finalTranscription, setFinalTranscription] = useState('')

    function startRecording() {
        startWebsocketRecording()
        setIsRecording(true)
    }

    function stopRecording() {
        stopWebsocketRecording()
        setIsRecording(false)
    }

    function handleTranscript(event) {
        const transcript_data = JSON.parse(event.data);
        const transcriptText = transcript_data['text']

        setTranscription(transcriptText)
        setFinalTranscription(`${finalTranscription}\n - ${transcriptText}`)
    }

    useEffect(() => {
        initWebSocket()
        websocket.onmessage = handleTranscript
    }, [])

    useEffect(() => {
        websocket.onmessage = handleTranscript
    }, [transcription])

    return { isRecording, transcription, finalTranscription, startRecording, stopRecording }
}